import React, { useState, useEffect, useRef } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import axios from 'axios';
import { 
  Typography, 
  Accordion, 
  AccordionSummary, 
  AccordionDetails,
  Box,
  Button,
  Grid,
  Paper,
  List,
  ListItem,
  ListItemText,
  IconButton,
  Dialog,
  DialogTitle,
  DialogContent,
  TextField,
  Checkbox,
  Collapse,
  FormControlLabel,
  Snackbar,
  Alert,
  FormControl,
  InputLabel,
  Select,
  MenuItem
} from '@mui/material';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import DeleteIcon from '@mui/icons-material/Delete';
import ZoomInIcon from '@mui/icons-material/ZoomIn';
import ZoomOutIcon from '@mui/icons-material/ZoomOut';
import RestartAltIcon from '@mui/icons-material/RestartAlt';
import CloseIcon from '@mui/icons-material/Close';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import EditIcon from '@mui/icons-material/Edit';
import PhotoCameraIcon from '@mui/icons-material/PhotoCamera';
import SaveIcon from '@mui/icons-material/Save';
import CancelIcon from '@mui/icons-material/Cancel';
import AddIcon from '@mui/icons-material/Add';
import AddCircleOutlineIcon from '@mui/icons-material/AddCircleOutline';
import ClearIcon from '@mui/icons-material/Clear';
import PlayArrowIcon from '@mui/icons-material/PlayArrow';
import { TransformWrapper, TransformComponent } from "react-zoom-pan-pinch";
import './EKG_QuizEditor.css';
import placeholderImage from '../assets/EKG_example.png';
import EkgQuiz from './EKG_Quiz';

// Verbesserte Funktion zum Parsen verschachtelter JSON-Strings
const deepParseJSON = (data) => {
  if (typeof data === 'string') {
    try {
      return deepParseJSON(JSON.parse(data));
    } catch (e) {
      // Wenn es nicht geparst werden kann, gib den String zurück
      return data;
    }
  } else if (typeof data === 'object' && data !== null) {
    // Durchlaufe alle Eigenschaften des Objekts
    Object.keys(data).forEach(key => {
      // Rekursiver Aufruf für verschachtelte Objekte und Arrays
      if (typeof data[key] === 'string') {
        try {
          data[key] = deepParseJSON(data[key]);
        } catch (e) {
          // Wenn es nicht geparst werden kann, belasse es als String
        }
      } else if (typeof data[key] === 'object' && data[key] !== null) {
        data[key] = deepParseJSON(data[key]);
      }
    });
  }
  return data;
};

function EKG_QuizEditor() {
  const { frageId } = useParams();
  const navigate = useNavigate();
  const fileInput = useRef(null);
  
  // Zustand für die Formular-Daten
  const [formData, setFormData] = useState({
    name: 'Neue EKG Quizfrage',
    bild_url: '',
    klappentext: 'Beschreibung der Frage hier eingeben...',
    checked_elemente: {
      standard: {
        kategorien: [],
        unterkategorien: [],
        optionen: []
      },
      custom: {
        kategorien: [],
        eigenstaendige_optionen: []
      }
    },
    richtige_elemente: [],
    schwierigkeitsgrad: 'mittel',
    berechtigte_dozenten: [-1]
  });
  
  // UI-Zustände
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [imageOpen, setImageOpen] = useState(false);
  const [textExpanded, setTextExpanded] = useState(true);
  const [titleEditMode, setTitleEditMode] = useState(false);
  const [selectedItems, setSelectedItems] = useState([]); // Ändere zu einem Array von Objekten
  const [statusMessage, setStatusMessage] = useState({
    open: false,
    message: '',
    severity: 'info'
  });
  
  // Verfügbare EKG-Diagnostik-Daten
  const [ekgDiagnostik, setEkgDiagnostik] = useState({});

  // Benutzerdefinierte Kategorien
  const [showCustomCategoryForm, setShowCustomCategoryForm] = useState(false);
  const [newCustomCategory, setNewCustomCategory] = useState('');
  const [customCategoryItems, setCustomCategoryItems] = useState([]);
  const [editingCategoryIndex, setEditingCategoryIndex] = useState(null);
  
  // Füge diese State-Variablen hinzu, um die Eingabefelder zu verwalten
  const [newSubcategoryNames, setNewSubcategoryNames] = useState({});
  const [newOptionNames, setNewOptionNames] = useState({});
  const [newSubcatOptionNames, setNewSubcatOptionNames] = useState({});

  // Füge diesen State hinzu:
  const [isAddingSubcategory, setIsAddingSubcategory] = useState(false);

  // Füge einen Ref für die letzte Ausführung hinzu
  const lastAddSubcategoryOptionTime = useRef(0);

  // Füge einen Ref für die letzte Ausführung hinzu
  const lastAddSubcategoryTime = useRef(0);

  // Zustand für Quiz-Test-Dialog
  const [quizTestOpen, setQuizTestOpen] = useState(false);

  // Laden der verfügbaren EKG-Diagnostik-Daten
  useEffect(() => {
    const fetchEkgData = async () => {
      try {
        const response = await axios.get('/api/ekg-diagnostik');
        setEkgDiagnostik(response.data);
        
        // Wenn eine neue Frage erstellt wird, aber nur beim ersten Laden
        if ((!frageId || frageId === 'new' || frageId.startsWith('temp-')) && 
            formData.checked_elemente.standard.kategorien.length === 0) {
          
          console.log("Initialisiere checked_elemente für neue Quizfrage");
          const updatedFormData = { ...formData };
          const allKategorien = [];
          const allUnterkategorien = [];
          const allOptionen = [];
          
          // Alle Kategorien, Unterkategorien und Optionen erfassen
          Object.entries(response.data).forEach(([category, subcategories]) => {
            const categoryId = category.toLowerCase().replace(/\s+/g, '-');
            allKategorien.push(categoryId);
            
            if (Array.isArray(subcategories)) {
              // Direkte Optionen unter einer Kategorie
              subcategories.forEach(item => {
                const optionId = `${categoryId}-${item.toLowerCase().replace(/\s+/g, '-')}`;
                allOptionen.push(optionId);
              });
            } else {
              // Unterkategorien mit Optionen
              Object.entries(subcategories).forEach(([subcat, items]) => {
                const subcatId = `${categoryId}-${subcat.toLowerCase().replace(/\s+/g, '-')}`;
                allUnterkategorien.push(subcatId);
                
                if (Array.isArray(items)) {
                  items.forEach(item => {
                    const optionId = `${subcatId}-${item.toLowerCase().replace(/\s+/g, '-')}`;
                    allOptionen.push(optionId);
                  });
                }
              });
            }
          });
          
          // Alle Elemente standardmäßig als ausgewählt markieren
          updatedFormData.checked_elemente.standard.kategorien = allKategorien;
          updatedFormData.checked_elemente.standard.unterkategorien = allUnterkategorien;
          updatedFormData.checked_elemente.standard.optionen = allOptionen;
          
          console.log("Initialisierte checked_elemente:", {
            kategorien: allKategorien.length,
            unterkategorien: allUnterkategorien.length,
            optionen: allOptionen.length
          });
          
          setFormData(updatedFormData);
        }
      } catch (error) {
        console.error('Fehler beim Laden der EKG-Diagnostik-Daten:', error);
        setError('Die EKG-Diagnostik-Daten konnten nicht geladen werden.');
      }
    };
    
    fetchEkgData();
  }, [frageId, formData.checked_elemente.standard.kategorien.length]);
  
  // Laden der Quizfrage, wenn eine ID vorhanden ist
  useEffect(() => {
    const fetchQuizFrage = async () => {
      if (frageId && frageId !== 'new' && !frageId.startsWith('temp-')) {
        try {
          setLoading(true);
          console.log("Lade Quizfrage mit ID:", frageId);
          
          const response = await axios.get(`/api/ekg-quizfragen/${frageId}`);
          let data = response.data;
          
          // Vollständig geparste Daten mit rekursiver Funktion
          data = deepParseJSON(data);
          
          console.log("Vollständig geparste Daten:", data);
          
          // Sicherstellen, dass checked_elemente die richtige Struktur hat
          if (!data.checked_elemente) {
            data.checked_elemente = {
              standard: {
                kategorien: [],
                unterkategorien: [],
                optionen: []
              },
              custom: {
                kategorien: [],
                eigenstaendige_optionen: []
              }
            };
          } else {
            // Stelle sicher, dass standard und custom Objekte existieren
            if (!data.checked_elemente.standard) {
              data.checked_elemente.standard = {
                kategorien: [],
                unterkategorien: [],
                optionen: []
              };
            }
            if (!data.checked_elemente.custom) {
              data.checked_elemente.custom = {
                kategorien: [],
                eigenstaendige_optionen: []
              };
            }
          }
          
          // Stelle sicher, dass richtige_elemente ein Array ist
          if (!Array.isArray(data.richtige_elemente)) {
            // Falls es ein String ist, versuche es zu parsen
            if (typeof data.richtige_elemente === 'string') {
              try {
                data.richtige_elemente = JSON.parse(data.richtige_elemente);
              } catch (e) {
                console.error("Fehler beim Parsen der richtigen Elemente:", e);
                data.richtige_elemente = [];
              }
            } else {
              data.richtige_elemente = [];
            }
          }
          
          console.log("Fertig vorbereitete Quiz-Daten:", data);
          
          // Setze die Formulardaten
          setFormData(data);
          
          // Hier die zentralisierte Funktion zum Aktualisieren der ausgewählten Elemente aufrufen
          updateSelectedItemsList(data);
          
          setLoading(false);
        } catch (error) {
          console.error("Fehler beim Laden der Quizfrage:", error);
          setError('Fehler beim Laden der Quizfrage: ' + (error.response?.data?.message || error.message));
          setLoading(false);
        }
      } else {
        setLoading(false);
      }
    };
    
    fetchQuizFrage();
  }, [frageId]);
  
  // Titel ändern
  const handleTitleChange = (event) => {
    setFormData({
      ...formData,
      name: event.target.value
    });
  };
  
  // Klappentext ändern
  const handleKlappentextChange = (event) => {
    setFormData({
      ...formData,
      klappentext: event.target.value
    });
  };
  
  // Toggle für Textexpansion
  const toggleTextExpand = () => {
    setTextExpanded(!textExpanded);
  };
  
  // Element zur Auswahl hinzufügen mit verbesserter hierarchischer Logik
  const toggleElementSelection = (element, type, id) => {
    // Checked_elemente aktualisieren
    const updatedFormData = { ...formData };
    const elementList = updatedFormData.checked_elemente.standard[type];
    
    if (elementList.includes(id)) {
      // Element entfernen, wenn es bereits ausgewählt ist
      updatedFormData.checked_elemente.standard[type] = elementList.filter(item => item !== id);
      
      // Wenn eine Kategorie oder Unterkategorie entfernt wird, müssen auch alle zugehörigen Elemente entfernt werden
      if (type === 'kategorien') {
        // Entferne alle Unterkategorien, die mit dieser Kategorie beginnen
        const categoryPrefix = id + '-';
        updatedFormData.checked_elemente.standard.unterkategorien = 
          updatedFormData.checked_elemente.standard.unterkategorien.filter(subcat => !subcat.startsWith(categoryPrefix));
        
        // Entferne alle Optionen, die mit dieser Kategorie beginnen
        updatedFormData.checked_elemente.standard.optionen = 
          updatedFormData.checked_elemente.standard.optionen.filter(opt => !opt.startsWith(categoryPrefix));
            
        // Lösche nur die Standard-Optionen aus richtige_elemente, die mit dieser Kategorie beginnen
        updatedFormData.richtige_elemente = 
          updatedFormData.richtige_elemente.filter(opt => {
            // Behalte alle benutzerdefinierten Optionen (die mit "custom-" beginnen)
            if (opt.startsWith('custom-')) return true;
            
            // Für Standard-Optionen, prüfe ob sie zu dieser Kategorie gehören
            return !(opt.startsWith('opt-') && opt.includes(categoryPrefix));
          });
      } 
      else if (type === 'unterkategorien') {
        // Entferne nur Optionen, die mit dieser Unterkategorie beginnen
        const subcatPrefix = id + '-';
        updatedFormData.checked_elemente.standard.optionen = 
          updatedFormData.checked_elemente.standard.optionen.filter(opt => !opt.startsWith(subcatPrefix));
            
        // Lösche nur die Standard-Optionen aus richtige_elemente, die mit dieser Unterkategorie beginnen
        updatedFormData.richtige_elemente = 
          updatedFormData.richtige_elemente.filter(opt => {
            // Behalte alle benutzerdefinierten Optionen
            if (opt.startsWith('custom-')) return true;
            
            // Für Standard-Optionen, prüfe ob sie zu dieser Unterkategorie gehören
            return !(opt.startsWith('opt-') && opt.includes(subcatPrefix));
          });
      }
      
      // Aktualisiere selectedItems entsprechend den richtigen_elementen
      updateSelectedItemsList(updatedFormData);
    } 
    else {
      // Element hinzufügen, wenn es noch nicht ausgewählt ist
      updatedFormData.checked_elemente.standard[type] = [...elementList, id];
      
      // Wenn eine Kategorie ausgewählt wird, füge alle zugehörigen Unterkategorien und Optionen hinzu
      if (type === 'kategorien') {
        const categoryPrefix = id + '-';
        
        // Wenn ekgDiagnostik vorhanden ist, finde alle passenden Unterkategorien und füge sie hinzu
        if (ekgDiagnostik) {
          const category = Object.keys(ekgDiagnostik).find(cat => 
            cat.toLowerCase().replace(/\s+/g, '-') === id);
          
          if (category) {
            if (typeof ekgDiagnostik[category] === 'object' && !Array.isArray(ekgDiagnostik[category])) {
              // Für jede Unterkategorie in dieser Kategorie
              Object.keys(ekgDiagnostik[category]).forEach(subcat => {
                const subcatId = `${id}-${subcat.toLowerCase().replace(/\s+/g, '-')}`;
                
                // Füge Unterkategorie hinzu, wenn sie noch nicht enthalten ist
                if (!updatedFormData.checked_elemente.standard.unterkategorien.includes(subcatId)) {
                  updatedFormData.checked_elemente.standard.unterkategorien.push(subcatId);
                }
                
                // Füge alle Optionen dieser Unterkategorie hinzu
                if (Array.isArray(ekgDiagnostik[category][subcat])) {
                  ekgDiagnostik[category][subcat].forEach(option => {
                    const optionId = `${subcatId}-${option.toLowerCase().replace(/\s+/g, '-')}`;
                    if (!updatedFormData.checked_elemente.standard.optionen.includes(optionId)) {
                      updatedFormData.checked_elemente.standard.optionen.push(optionId);
                    }
                  });
                }
              });
            } else if (Array.isArray(ekgDiagnostik[category])) {
              // Direkte Optionen in der Kategorie
              ekgDiagnostik[category].forEach(option => {
                const optionId = `${id}-${option.toLowerCase().replace(/\s+/g, '-')}`;
                if (!updatedFormData.checked_elemente.standard.optionen.includes(optionId)) {
                  updatedFormData.checked_elemente.standard.optionen.push(optionId);
                }
              });
            }
          }
        }
      }
      else if (type === 'unterkategorien') {
        // Füge alle Optionen dieser Unterkategorie hinzu
        const parts = id.split('-');
        if (parts.length >= 2) {
          const categoryId = parts[0];
          const subcatName = parts.slice(1).join('-');
          
          // Finde die Kategorie
          const category = Object.keys(ekgDiagnostik).find(cat => 
            cat.toLowerCase().replace(/\s+/g, '-') === categoryId);
          
          if (category) {
            // Finde die Unterkategorie
            const subcat = Object.keys(ekgDiagnostik[category]).find(sub => 
              sub.toLowerCase().replace(/\s+/g, '-') === subcatName);
            
            if (subcat && Array.isArray(ekgDiagnostik[category][subcat])) {
              // Füge alle Optionen hinzu
              ekgDiagnostik[category][subcat].forEach(option => {
                const optionId = `${id}-${option.toLowerCase().replace(/\s+/g, '-')}`;
                if (!updatedFormData.checked_elemente.standard.optionen.includes(optionId)) {
                  updatedFormData.checked_elemente.standard.optionen.push(optionId);
                }
              });
            }
          }
        }
      }
    }
    
    setFormData(updatedFormData);
  };
  
  // Bild hochladen
  const handleImageUpload = async (event) => {
    const file = event.target.files[0];
    if (!file) return;
    
    try {
      setLoading(true);
      
      const formDataObj = new FormData();
      formDataObj.append('file', file);
      
      const response = await axios.post('/api/upload-image', formDataObj, {
        headers: {
          'Content-Type': 'multipart/form-data'
        }
      });
      
      setFormData({
        ...formData,
        bild_url: response.data.location
      });
      
      setStatusMessage({
        open: true,
        message: 'Bild erfolgreich hochgeladen',
        severity: 'success'
      });
      
      setLoading(false);
    } catch (error) {
      console.error('Fehler beim Hochladen des Bildes:', error);
      setStatusMessage({
        open: true,
        message: 'Fehler beim Hochladen des Bildes: ' + (error.response?.data?.message || error.message),
        severity: 'error'
      });
      setLoading(false);
    }
  };
  
  // Formular absenden
  const handleSubmit = async () => {
    try {
      setLoading(true);
      
      let response;
      
      if (frageId && frageId !== 'new' && !frageId.startsWith('temp-')) {
        console.log("Aktualisiere Quizfrage mit ID:", frageId);
        response = await axios.put(`/api/ekg-quizfragen/${frageId}`, formData);
      } else {
        console.log("Erstelle neue Quizfrage");
        response = await axios.post('/api/ekg-quizfragen', formData);
      }
      
      setStatusMessage({
        open: true,
        message: 'Quizfrage erfolgreich gespeichert',
        severity: 'success'
      });
      
      // Nach dem Speichern zurück navigieren
      setTimeout(() => {
        navigate(-1);
      }, 1500);
      
    } catch (error) {
      console.error('Fehler beim Speichern der Quizfrage:', error);
      setStatusMessage({
        open: true,
        message: 'Fehler beim Speichern der Quizfrage: ' + (error.response?.data?.message || error.message),
        severity: 'error'
      });
      setLoading(false);
    }
  };
  
  // Hilfsfunktion zum Renderisieren der Unterkategorien und Optionen - verbesserte Version
  const renderSubcategories = (category, subcategories) => {
    // Fall: subcategories ist ein Array von Strings (direkte Optionen unter einer Kategorie)
    if (Array.isArray(subcategories)) {
      return subcategories.map((item, i) => {
        const optionId = `${category.toLowerCase()}-${item.toLowerCase().replace(/\s+/g, '-')}`;
        
        // Standardmäßig sind alle Optionen im Editor sichtbar
        const isSelected = formData.checked_elemente.standard.optionen.includes(optionId);
        const isCorrect = formData.richtige_elemente.includes(`opt-${optionId}`);
        
        return (
          <Box 
            key={i} 
            className={`option-checkbox ${isSelected ? 'selected' : ''} ${isCorrect ? 'correct' : ''}`}
          >
            <Box display="flex" alignItems="center" justifyContent="space-between" width="100%">
              <FormControlLabel
                control={
                  <Checkbox 
                    checked={isSelected}
                    onChange={() => toggleElementSelection(item, 'optionen', optionId)}
                  />
                }
                label={<Typography>{item}</Typography>}
              />
              
              {/* Zeige "Richtig"-Checkbox nur, wenn Option ausgewählt ist */}
              {isSelected && (
                <Box className={`correct-option-box ${isCorrect ? 'selected' : ''}`}>
                  <FormControlLabel
                    control={
                      <Checkbox 
                        checked={isCorrect}
                        onChange={() => toggleCorrectOption(item, optionId)}
                        color="success"
                        className="correct-checkbox"
                      />
                    }
                    label={<Typography className="correct-option-label">Richtig</Typography>}
                  />
                </Box>
              )}
            </Box>
          </Box>
        );
      });
    }

    // Fall: subcategories ist ein Objekt mit Unterkategorien
    return Object.entries(subcategories).map(([subcat, items]) => {
      const subcatId = `${category.toLowerCase()}-${subcat.toLowerCase().replace(/\s+/g, '-')}`;
      
      // Standardmäßig sind alle Unterkategorien sichtbar
      const isSelected = formData.checked_elemente.standard.unterkategorien.includes(subcatId);
      
      return (
        <Box key={subcat} mb={2}>
          <Box className={`subcategory-checkbox ${isSelected ? 'selected' : ''}`}>
            <FormControlLabel
              control={
                <Checkbox 
                  checked={isSelected}
                  onChange={() => toggleElementSelection(subcat, 'unterkategorien', subcatId)}
                />
              }
              label={<Typography variant="subtitle1">{subcat}</Typography>}
            />
          </Box>
          
          <Box ml={4}>
            {Array.isArray(items) && items.length > 0 && items.map((item, i) => {
              const optionId = `${subcatId}-${item.toLowerCase().replace(/\s+/g, '-')}`;
              const isOptionSelected = formData.checked_elemente.standard.optionen.includes(optionId);
              const isOptionCorrect = formData.richtige_elemente.includes(`opt-${optionId}`);
              
              return (
                <Box 
                  key={i} 
                  className={`option-checkbox ${isOptionSelected ? 'selected' : ''} ${isOptionCorrect ? 'correct' : ''}`}
                >
                  <Box display="flex" alignItems="center" justifyContent="space-between" width="100%">
                    <FormControlLabel
                      control={
                        <Checkbox 
                          checked={isOptionSelected}
                          onChange={() => toggleElementSelection(item, 'optionen', optionId)}
                        />
                      }
                      label={<Typography>{item}</Typography>}
                    />
                    
                    {/* Zeige "Richtig"-Checkbox nur, wenn Option ausgewählt ist */}
                    {isOptionSelected && (
                      <Box className={`correct-option-box ${isOptionCorrect ? 'selected' : ''}`}>
                        <FormControlLabel
                          control={
                            <Checkbox 
                              checked={isOptionCorrect}
                              onChange={() => toggleCorrectOption(item, optionId)}
                              color="success"
                              className="correct-checkbox"
                            />
                          }
                          label={<Typography className="correct-option-label">Richtig</Typography>}
                        />
                      </Box>
                    )}
                  </Box>
                </Box>
              );
            })}
          </Box>
        </Box>
      );
    });
  };

  // Generiere eine eindeutige ID für benutzerdefinierte Elemente
  const generateCustomId = (prefix, name) => {
    return `custom-${prefix}-${name.toLowerCase().replace(/\s+/g, '-')}-${Date.now().toString(36)}`;
  };

  // Kategorie hinzufügen
  const addCustomCategory = () => {
    if (!newCustomCategory.trim()) return;
    
    const categoryId = generateCustomId('kat', newCustomCategory);
    const newCategory = {
      id: categoryId,
      name: newCustomCategory,
      unterkategorien: [],
      optionen: []
    };
    
    // Aktualisiere formData
    const updatedFormData = { ...formData };
    updatedFormData.checked_elemente.custom.kategorien.push(newCategory);
    setFormData(updatedFormData);
    
    // Aktualisiere UI
    setCustomCategoryItems([...customCategoryItems, newCategory]);
    setNewCustomCategory('');
    setShowCustomCategoryForm(false);
  };

  // Unterkategorie hinzufügen - mit eindeutigem Identifikator
  const addCustomSubcategory = (categoryIndex, subcategoryName, uniqueId = Date.now().toString()) => {
    // Verwende einen statischen Identifikator für jeden Aufruf
    console.log(`Versuche Unterkategorie hinzuzufügen: ${subcategoryName} mit ID: ${uniqueId}`);
    
    // Prüfe ob diese Funktion mit dieser ID bereits ausgeführt wurde
    if (window.lastAddedSubcategory === uniqueId) {
      console.log(`Verhindere doppelten Aufruf für ID: ${uniqueId}`);
      return;
    }
    
    // Setze die ID als bereits verarbeitet
    window.lastAddedSubcategory = uniqueId;
    
    // Rest der Funktion bleibt gleich
    if (!subcategoryName || !subcategoryName.trim()) {
      return;
    }
    
    // Prüfe, ob bereits eine Unterkategorie mit diesem Namen existiert
    const isDuplicate = customCategoryItems[categoryIndex]?.unterkategorien?.some(
      subcat => subcat.name.trim().toLowerCase() === subcategoryName.trim().toLowerCase()
    );
    
    if (isDuplicate) {
      console.log('Eine Unterkategorie mit diesem Namen existiert bereits, wird nicht hinzugefügt');
      return;
    }
    
    // Erstelle wirklich tiefe Kopien der Objekte, um Referenzprobleme zu vermeiden
    const updatedFormData = JSON.parse(JSON.stringify(formData));
    
    const subcategoryId = generateCustomId('subkat', subcategoryName);
    const newSubcategory = {
      id: subcategoryId,
      name: subcategoryName,
      optionen: []
    };
    
    console.log(`Füge Unterkategorie hinzu: ${subcategoryName}`);
    
    // Füge nur zur formData hinzu
    updatedFormData.checked_elemente.custom.kategorien[categoryIndex].unterkategorien.push(newSubcategory);
    
    // Setze das gesamte Formular auf einmal, um Referenzprobleme zu vermeiden
    setFormData(updatedFormData);
    
    // Setze einen Timeout, um den Identifikator zurückzusetzen
    setTimeout(() => {
      if (window.lastAddedSubcategory === uniqueId) {
        window.lastAddedSubcategory = null;
      }
    }, 2000);
  };

  // Option zu Kategorie hinzufügen - mit zeitbasiertem Debounce und eindeutigem Identifikator
  const addCustomOption = (categoryIndex, optionName, uniqueId = Date.now().toString()) => {
    // Verwende einen statischen Identifikator für jeden Aufruf
    console.log(`Versuche Option hinzuzufügen: ${optionName} mit ID: ${uniqueId}`);
    
    // Prüfe ob diese Funktion mit dieser ID bereits ausgeführt wurde
    if (window.lastAddedOption === uniqueId) {
      console.log(`Verhindere doppelten Aufruf für ID: ${uniqueId}`);
      return;
    }
    
    // Setze die ID als bereits verarbeitet
    window.lastAddedOption = uniqueId;
    
    // Rest der Funktion bleibt gleich
    if (!optionName || !optionName.trim()) {
      return;
    }
    
    // Prüfe, ob bereits eine Option mit diesem Namen existiert
    const isDuplicate = customCategoryItems[categoryIndex]?.optionen?.some(
      opt => opt.name.trim().toLowerCase() === optionName.trim().toLowerCase()
    );
    
    if (isDuplicate) {
      console.log('Eine Option mit diesem Namen existiert bereits, wird nicht hinzugefügt');
      setStatusMessage({
        open: true,
        message: `Eine Option mit dem Namen "${optionName}" existiert bereits in dieser Kategorie.`,
        severity: 'warning'
      });
      return;
    }
    
    // Erstelle wirklich tiefe Kopien der Objekte, um Referenzprobleme zu vermeiden
    const updatedFormData = JSON.parse(JSON.stringify(formData));
    
    const optionId = generateCustomId('opt', optionName);
    const newOption = {
      id: optionId,
      name: optionName
    };
    
    console.log(`Füge Option hinzu: ${optionName}`);
    
    // Füge nur zur formData hinzu
    updatedFormData.checked_elemente.custom.kategorien[categoryIndex].optionen.push(newOption);
    
    // Setze das gesamte Formular auf einmal, um Referenzprobleme zu vermeiden
    setFormData(updatedFormData);
    
    console.log(`Option "${optionName}" erfolgreich hinzugefügt`);
    
    // Setze einen Timeout, um den Identifikator zurückzusetzen
    setTimeout(() => {
      if (window.lastAddedOption === uniqueId) {
        window.lastAddedOption = null;
      }
    }, 2000);
  };

  // Option zu Unterkategorie hinzufügen - mit eindeutigem Identifikator
  const addCustomSubcategoryOption = (categoryIndex, subcategoryIndex, optionName, uniqueId = Date.now().toString()) => {
    // Verwende einen statischen Identifikator für jeden Aufruf
    console.log(`Versuche Unterkategorie-Option hinzuzufügen: ${optionName} mit ID: ${uniqueId}`);
    
    // Prüfe ob diese Funktion mit dieser ID bereits ausgeführt wurde
    if (window.lastAddedSubcatOption === uniqueId) {
      console.log(`Verhindere doppelten Aufruf für ID: ${uniqueId}`);
      return;
    }
    
    // Setze die ID als bereits verarbeitet
    window.lastAddedSubcatOption = uniqueId;
    
    // Rest der Funktion bleibt gleich
    if (!optionName || !optionName.trim()) {
      return;
    }
    
    // Prüfe, ob bereits eine Option mit diesem Namen existiert
    const isDuplicate = customCategoryItems[categoryIndex]?.unterkategorien[subcategoryIndex]?.optionen?.some(
      opt => opt.name.trim().toLowerCase() === optionName.trim().toLowerCase()
    );
    
    if (isDuplicate) {
      console.log('Eine Option mit diesem Namen existiert bereits, wird nicht hinzugefügt');
      setStatusMessage({
        open: true,
        message: `Eine Option mit dem Namen "${optionName}" existiert bereits in dieser Unterkategorie.`,
        severity: 'warning'
      });
      return;
    }
    
    // Erstelle wirklich tiefe Kopien der Objekte, um Referenzprobleme zu vermeiden
    const updatedFormData = JSON.parse(JSON.stringify(formData));
    
    const optionId = generateCustomId('opt', optionName);
    const newOption = {
      id: optionId,
      name: optionName
    };
    
    console.log(`Füge Unterkategorie-Option hinzu: ${optionName}`);
    
    // Füge nur zur formData hinzu
    updatedFormData.checked_elemente.custom.kategorien[categoryIndex]
      .unterkategorien[subcategoryIndex].optionen.push(newOption);
    
    // Setze das gesamte Formular auf einmal, um Referenzprobleme zu vermeiden
    setFormData(updatedFormData);
    
    console.log(`Unterkategorie-Option "${optionName}" erfolgreich hinzugefügt`);
    
    // Setze einen Timeout, um den Identifikator zurückzusetzen
    setTimeout(() => {
      if (window.lastAddedSubcatOption === uniqueId) {
        window.lastAddedSubcatOption = null;
      }
    }, 2000);
  };

  // Kategorie löschen
  const deleteCustomCategory = (categoryIndex) => {
    const updatedFormData = { ...formData };
    const updatedCustomItems = [...customCategoryItems];
    const categoryToDelete = updatedCustomItems[categoryIndex];
    
    // Entferne aus richtige_elemente alle Optionen dieser Kategorie
    if (categoryToDelete) {
      const optionsToRemove = [];
      
      // Sammle alle Optionen-IDs, die entfernt werden müssen
      // Direkte Optionen der Kategorie
      categoryToDelete.optionen.forEach(option => {
        optionsToRemove.push(option.id);
      });
      
      // Optionen aus Unterkategorien
      categoryToDelete.unterkategorien.forEach(subcat => {
        subcat.optionen.forEach(option => {
          optionsToRemove.push(option.id);
        });
      });
      
      // Entferne aus richtige_elemente
      updatedFormData.richtige_elemente = updatedFormData.richtige_elemente
        .filter(id => !optionsToRemove.includes(id));
      
      // Aktualisiere auch selectedItems
      setSelectedItems(selectedItems.filter(item => !optionsToRemove.includes(item.id)));
    }
    
    // Entferne die Kategorie
    updatedFormData.checked_elemente.custom.kategorien.splice(categoryIndex, 1);
    updatedCustomItems.splice(categoryIndex, 1);
    
    setFormData(updatedFormData);
    setCustomCategoryItems(updatedCustomItems);
  };

  // Unterkategorie löschen
  const deleteCustomSubcategory = (categoryIndex, subcategoryIndex) => {
    const updatedFormData = { ...formData };
    const updatedCustomItems = [...customCategoryItems];
    
    // Sammle Optionen-IDs, die entfernt werden müssen
    const subcatToDelete = updatedCustomItems[categoryIndex].unterkategorien[subcategoryIndex];
    const optionsToRemove = subcatToDelete.optionen.map(option => option.id);
    
    // Entferne aus richtige_elemente
    updatedFormData.richtige_elemente = updatedFormData.richtige_elemente
      .filter(id => !optionsToRemove.includes(id));
    
    // Aktualisiere auch selectedItems
    setSelectedItems(selectedItems.filter(item => !optionsToRemove.includes(item.id)));
    
    // Entferne die Unterkategorie
    updatedFormData.checked_elemente.custom.kategorien[categoryIndex].unterkategorien.splice(subcategoryIndex, 1);
    updatedCustomItems[categoryIndex].unterkategorien.splice(subcategoryIndex, 1);
    
    setFormData(updatedFormData);
    setCustomCategoryItems(updatedCustomItems);
  };

  // Option löschen
  const deleteCustomOption = (categoryIndex, optionIndex, subcategoryIndex = null) => {
    const updatedFormData = { ...formData };
    const updatedCustomItems = [...customCategoryItems];
    
    let optionToDelete;
    
    if (subcategoryIndex === null) {
      // Direkte Option der Kategorie
      optionToDelete = updatedCustomItems[categoryIndex].optionen[optionIndex];
      
      // Entferne die Option
      updatedFormData.checked_elemente.custom.kategorien[categoryIndex].optionen.splice(optionIndex, 1);
      updatedCustomItems[categoryIndex].optionen.splice(optionIndex, 1);
    } else {
      // Option einer Unterkategorie
      optionToDelete = updatedCustomItems[categoryIndex].unterkategorien[subcategoryIndex].optionen[optionIndex];
      
      // Entferne die Option
      updatedFormData.checked_elemente.custom.kategorien[categoryIndex]
        .unterkategorien[subcategoryIndex].optionen.splice(optionIndex, 1);
      updatedCustomItems[categoryIndex].unterkategorien[subcategoryIndex].optionen.splice(optionIndex, 1);
    }
    
    // Entferne aus richtige_elemente
    updatedFormData.richtige_elemente = updatedFormData.richtige_elemente
      .filter(id => id !== optionToDelete.id);
    
    // Aktualisiere auch selectedItems
    setSelectedItems(selectedItems.filter(item => item.id !== optionToDelete.id));
    
    setFormData(updatedFormData);
    setCustomCategoryItems(updatedCustomItems);
  };

  // Laden der benutzerdefinierten Kategorien beim Start
  useEffect(() => {
    if (formData.checked_elemente?.custom?.kategorien?.length > 0) {
      setCustomCategoryItems(formData.checked_elemente.custom.kategorien);
    }
  }, [formData.checked_elemente.custom.kategorien]);

  // Füge am Anfang der Komponente eine Debug-Ausgabe hinzu
  useEffect(() => {
    if (customCategoryItems.length > 0) {
      console.log("Aktuelle Unterkategorien:", 
        customCategoryItems.map(cat => ({
          name: cat.name,
          subcatCount: cat.unterkategorien.length,
          subcats: cat.unterkategorien.map(sub => sub.name)
        }))
      );
    }
  }, [customCategoryItems]);

  // Die fehlenden Funktionen für EKG_QuizEditor.js

  // 1. toggleCorrectOption - Funktion zum Markieren/Demarkieren einer Option als richtig (für Standard-Optionen)
  const toggleCorrectOption = (optionText, optionId) => {
    const elementId = `opt-${optionId}`;
    const updatedFormData = { ...formData };
    
    if (updatedFormData.richtige_elemente.includes(elementId)) {
      // Option aus richtigen Elementen entfernen
      updatedFormData.richtige_elemente = 
        updatedFormData.richtige_elemente.filter(id => id !== elementId);
    } else {
      // Option zu richtigen Elementen hinzufügen
      updatedFormData.richtige_elemente = [...updatedFormData.richtige_elemente, elementId];
    }
    
    // Aktualisiere selectedItems basierend auf richtige_elemente
    updateSelectedItemsList(updatedFormData);
    
    setFormData(updatedFormData);
  };

  // 2. toggleCustomCorrectOption - Funktion zum Markieren/Demarkieren einer benutzerdefinierten Option als richtig
  const toggleCustomCorrectOption = (optionId, optionName) => {
    const updatedFormData = { ...formData };
    
    if (updatedFormData.richtige_elemente.includes(optionId)) {
      // Option aus richtigen Elementen entfernen
      updatedFormData.richtige_elemente = 
        updatedFormData.richtige_elemente.filter(id => id !== optionId);
    } else {
      // Option zu richtigen Elementen hinzufügen
      updatedFormData.richtige_elemente = [...updatedFormData.richtige_elemente, optionId];
    }
    
    // Aktualisiere selectedItems basierend auf richtige_elemente
    updateSelectedItemsList(updatedFormData);
    
    setFormData(updatedFormData);
  };

  // 3. removeItem - Funktion zum Entfernen eines ausgewählten Elements aus der Liste
  const removeItem = (index) => {
    const updatedFormData = { ...formData };
    const itemToRemove = selectedItems[index];
    
    // Element aus richtige_elemente entfernen
    updatedFormData.richtige_elemente = updatedFormData.richtige_elemente
      .filter(id => id !== itemToRemove.id);
    
    // Aus selectedItems entfernen
    const updatedSelectedItems = [...selectedItems];
    updatedSelectedItems.splice(index, 1);
    
    setFormData(updatedFormData);
    setSelectedItems(updatedSelectedItems);
  };

  // 4. clearAll - Funktion zum Löschen aller richtigen Elemente
  const clearAll = () => {
    const updatedFormData = { ...formData };
    updatedFormData.richtige_elemente = [];
    
    setFormData(updatedFormData);
    setSelectedItems([]);
  };

  // Verbesserte Hilfsfunktion zum Aktualisieren der selectedItems-Liste basierend auf richtige_elemente
  const updateSelectedItemsList = (updatedFormData) => {
    const updatedSelectedItems = [];
    
    // Logge alle richtigen Elemente zur Diagnose
    console.log("Richtige Elemente zum Aktualisieren:", updatedFormData.richtige_elemente);
    
    // Durchlaufe alle richtigen Elemente
    updatedFormData.richtige_elemente.forEach(elementId => {
      // Für Standard-Optionen
      if (elementId.startsWith('opt-')) {
        const parts = elementId.split('-');
        if (parts.length >= 3) {
          updatedSelectedItems.push({
            text: parts.slice(2).join('-').replace(/-/g, ' '),
            id: elementId
          });
        }
      } 
      // Für benutzerdefinierte Optionen
      else if (elementId.startsWith('custom-')) {
        // Finde den Namen der Option in den benutzerdefinierten Kategorien
        let optionName = null;
        let foundInCategories = false;
        
        // Suche in allen benutzerdefinierten Kategorien
        if (updatedFormData.checked_elemente && updatedFormData.checked_elemente.custom) {
          const customCategories = updatedFormData.checked_elemente.custom.kategorien || [];
          
          // Durchsuche alle Kategorien
          for (const category of customCategories) {
            // Suche in direkten Optionen der Kategorie
            if (category.optionen) {
              const directOption = category.optionen.find(opt => opt.id === elementId);
              if (directOption) {
                optionName = directOption.name;
                foundInCategories = true;
                break;
              }
            }
            
            // Suche in Unterkategorien
            if (category.unterkategorien) {
              for (const subcategory of category.unterkategorien) {
                if (subcategory.optionen) {
                  const subcatOption = subcategory.optionen.find(opt => opt.id === elementId);
                  if (subcatOption) {
                    optionName = subcatOption.name;
                    foundInCategories = true;
                    break;
                  }
                }
              }
              if (foundInCategories) break;
            }
          }
        }
        
        // Wenn kein Name gefunden wurde, verwende die ID als Fallback-Text
        if (!optionName) {
          optionName = `Option ${elementId.split('-').pop()}`;
          console.log(`Warnung: Konnte keinen Namen für benutzerdefinierte Option ${elementId} finden`);
        }
        
        updatedSelectedItems.push({
          text: optionName,
          id: elementId
        });
      }
    });
    
    console.log("Aktualisierte ausgewählte Elemente:", updatedSelectedItems);
    setSelectedItems(updatedSelectedItems);
  };

  return (
    <Box className="ekg-quiz-editor-container">
      {/* Titel mit Edit-Button - Zentriert */}
      <Box className="editor-title">
        {titleEditMode ? (
          <TextField
            value={formData.name}
            onChange={handleTitleChange}
            variant="standard"
            fullWidth
            autoFocus
            onBlur={() => setTitleEditMode(false)}
            onKeyDown={e => e.key === 'Enter' && setTitleEditMode(false)}
            inputProps={{ style: { textAlign: 'center' } }} // Text zentrieren
          />
        ) : (
          <>
            <Typography variant="h4" onClick={() => setTitleEditMode(true)} style={{ cursor: 'pointer' }}>
              {formData.name}
            </Typography>
            <IconButton onClick={() => setTitleEditMode(true)} size="small">
              <EditIcon />
            </IconButton>
          </>
        )}
      </Box>

      {loading && <Typography>Lade Daten...</Typography>}
      {error && <Typography color="error">{error}</Typography>}

      {/* EKG Bild mit Upload-Button */}
      <Paper className="ekg-image-container">
        <img 
          src={formData.bild_url || placeholderImage} 
          alt="EKG"
          className="ekg-image"
          onClick={() => setImageOpen(true)}
        />
        <Box className="image-controls">
          <Button
            variant="contained"
            startIcon={<PhotoCameraIcon />}
            size="small"
            onClick={() => fileInput.current.click()}
          >
            Bild ändern
          </Button>
          <input
            type="file"
            hidden
            ref={fileInput}
            accept="image/*"
            onChange={handleImageUpload}
          />
          <IconButton 
            className="zoom-button"
            onClick={() => setImageOpen(true)}
          >
            <ZoomInIcon />
          </IconButton>
        </Box>
      </Paper>

      {/* Schwierigkeitsgrad */}
      <Paper className="difficulty-panel">
        <Typography variant="h6" gutterBottom>Schwierigkeitsgrad</Typography>
        <FormControl fullWidth>
          <InputLabel>Schwierigkeitsgrad</InputLabel>
          <Select
            value={formData.schwierigkeitsgrad}
            onChange={(e) => setFormData({...formData, schwierigkeitsgrad: e.target.value})}
          >
            <MenuItem value="leicht">Leicht</MenuItem>
            <MenuItem value="mittel">Mittel</MenuItem>
            <MenuItem value="schwierig">Schwierig</MenuItem>
          </Select>
        </FormControl>
      </Paper>

      {/* Editierbarer Klappentext */}
      <Paper className="ekg-description-panel">
        <Button 
          fullWidth
          onClick={toggleTextExpand}
          className="expand-description-button"
          endIcon={<KeyboardArrowDownIcon className={textExpanded ? 'arrow-expanded' : ''} />}
        >
          <Typography variant="button" className="expand-text">
            {textExpanded ? "Beschreibung ausblenden" : "Beschreibung anzeigen"}
          </Typography>
        </Button>
        <Collapse in={textExpanded} timeout="auto">
          <Box className="ekg-description-content">
            <TextField
              multiline
              fullWidth
              minRows={3}
              value={formData.klappentext}
              onChange={handleKlappentextChange}
              placeholder="Beschreibung hier eingeben..."
              variant="outlined"
            />
          </Box>
        </Collapse>
      </Paper>

      {/* Ausgewählte richtige Elemente mit nativem Browser-Resizing */}
      <Box className="native-resizable-container">
        <Typography variant="h6" className="resizable-textarea-title">
          Ausgewählte richtige Elemente
        </Typography>
        <div className="native-resizable-box">
          <div className="native-box-header">
            <Typography variant="subtitle1">
              {selectedItems.length} {selectedItems.length === 1 ? 'Element' : 'Elemente'} ausgewählt
            </Typography>
          </div>
          <List className="native-box-content">
            {selectedItems.length > 0 ? (
              selectedItems.map((item, index) => (
                <ListItem 
                  key={index}
                  secondaryAction={
                    <IconButton edge="end" onClick={() => removeItem(index)}>
                      <DeleteIcon />
                    </IconButton>
                  }
                >
                  <ListItemText primary={item.text} />
                </ListItem>
              ))
            ) : (
              <ListItem>
                <ListItemText 
                  primary="Keine richtigen Elemente ausgewählt" 
                  secondary="Wähle die richtigen Antworten über die Checkboxen bei den Optionen aus" 
                />
              </ListItem>
            )}
          </List>
          <div className="native-box-footer">
            <Button 
              variant="text" 
              color="error" 
              onClick={clearAll}
              startIcon={<DeleteIcon />}
              size="small"
            >
              Alle löschen
            </Button>
          </div>
        </div>
      </Box>

      {/* Accordion-Gruppen für EKG-Diagnostik */}
      {!loading && (
        <Grid container spacing={2} className="accordion-categories">
          {/* Bestehende Standard-Kategorien */}
          {ekgDiagnostik && Object.keys(ekgDiagnostik).length > 0 && 
            Object.entries(ekgDiagnostik).map(([category, subcategories], index) => {
              const categoryId = category.toLowerCase().replace(/\s+/g, '-');
              const isCategorySelected = formData.checked_elemente.standard.kategorien.includes(categoryId);
              
              return (
                <Grid item xs={12} md={4} key={`standard-${index}`}>
                  <Accordion>
                    <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                      <Box className={`category-checkbox ${isCategorySelected ? 'selected' : ''}`} width="100%">
                        <FormControlLabel
                          control={
                            <Checkbox 
                              checked={isCategorySelected}
                              onChange={() => toggleElementSelection(category, 'kategorien', categoryId)}
                              onClick={(e) => e.stopPropagation()}
                            />
                          }
                          label={<Typography>{category}</Typography>}
                          onClick={(e) => e.stopPropagation()}
                        />
                      </Box>
                    </AccordionSummary>
                    <AccordionDetails>
                      {renderSubcategories(category, subcategories)}
                    </AccordionDetails>
                  </Accordion>
                </Grid>
              );
          })}
          
          {/* Benutzerdefinierte Kategorien */}
          {customCategoryItems.map((category, categoryIndex) => (
            <Grid item xs={12} md={4} key={`custom-${categoryIndex}`}>
              <Accordion className="custom-accordion">
                <AccordionSummary 
                  expandIcon={<ExpandMoreIcon />}
                  onClick={(e) => {
                    // Keine zusätzliche Logik beim Klicken auf die Zusammenfassung
                  }}
                >
                  <Box className="category-title-custom" width="100%" onClick={(e) => e.stopPropagation()}>
                    <Typography>{category.name}</Typography>
                    <IconButton 
                      size="small"
                      onClick={(e) => {
                        e.stopPropagation();
                        e.preventDefault(); // Wichtig: Verhindert auch das Default-Verhalten
                        deleteCustomCategory(categoryIndex);
                      }}
                      className="delete-category-button"
                    >
                      <ClearIcon />
                    </IconButton>
                  </Box>
                </AccordionSummary>
                <AccordionDetails>
                  {/* Unterkategorie hinzufügen Input */}
                  <Box className="add-subcategory-container">
                    <TextField
                      placeholder="Neue Unterkategorie"
                      variant="outlined"
                      size="small"
                      value={newSubcategoryNames[categoryIndex] || ''}
                      onChange={(e) => {
                        const updatedNames = { ...newSubcategoryNames };
                        updatedNames[categoryIndex] = e.target.value;
                        setNewSubcategoryNames(updatedNames);
                      }}
                      onKeyDown={(e) => {
                        if (e.key === 'Enter' && e.target.value.trim()) {
                          e.preventDefault(); // Verhindert das Absenden eines Formulars
                          e.stopPropagation(); // Verhindert Bubble-Up des Events
                          // Generiere eine eindeutige ID für diesen spezifischen Aufruf
                          const uniqueCallId = `key-${Date.now()}`;
                          addCustomSubcategory(categoryIndex, newSubcategoryNames[categoryIndex], uniqueCallId);
                          
                          // Eingabefeld leeren
                          const updatedNames = { ...newSubcategoryNames };
                          updatedNames[categoryIndex] = '';
                          setNewSubcategoryNames(updatedNames);
                        }
                      }}
                      onClick={(e) => e.stopPropagation()} // Verhindert, dass sich das Akkordeon schließt
                    />
                    <Button
                      variant="text"
                      startIcon={<AddIcon />}
                      onClick={(e) => {
                        e.preventDefault();
                        e.stopPropagation();
                        if (newSubcategoryNames[categoryIndex]?.trim()) {
                          // Generiere eine eindeutige ID für diesen spezifischen Aufruf
                          const uniqueCallId = `btn-${Date.now()}`;
                          addCustomSubcategory(categoryIndex, newSubcategoryNames[categoryIndex], uniqueCallId);
                          
                          // Eingabefeld leeren
                          const updatedNames = { ...newSubcategoryNames };
                          updatedNames[categoryIndex] = '';
                          setNewSubcategoryNames(updatedNames);
                        }
                      }}
                    >
                      Unterkategorie
                    </Button>
                  </Box>
                  
                  {/* Option direkt zu Kategorie hinzufügen */}
                  <Box className="add-option-container">
                    <TextField
                      placeholder="Neue Option"
                      variant="outlined"
                      size="small"
                      value={newOptionNames[categoryIndex] || ''}
                      onChange={(e) => {
                        const updatedNames = { ...newOptionNames };
                        updatedNames[categoryIndex] = e.target.value;
                        setNewOptionNames(updatedNames);
                      }}
                      onKeyDown={(e) => {
                        if (e.key === 'Enter' && e.target.value.trim()) {
                          e.preventDefault();
                          e.stopPropagation();
                          // Generiere eine eindeutige ID für diesen spezifischen Aufruf
                          const uniqueCallId = `key-opt-${Date.now()}`;
                          addCustomOption(categoryIndex, newOptionNames[categoryIndex], uniqueCallId);
                          
                          // Eingabefeld leeren
                          const updatedNames = { ...newOptionNames };
                          updatedNames[categoryIndex] = '';
                          setNewOptionNames(updatedNames);
                        }
                      }}
                      onClick={(e) => e.stopPropagation()}
                    />
                    <Button
                      variant="text"
                      startIcon={<AddIcon />}
                      onClick={(e) => {
                        e.preventDefault();
                        e.stopPropagation();
                        if (newOptionNames[categoryIndex]?.trim()) {
                          // Generiere eine eindeutige ID für diesen spezifischen Aufruf
                          const uniqueCallId = `btn-opt-${Date.now()}`;
                          addCustomOption(categoryIndex, newOptionNames[categoryIndex], uniqueCallId);
                          
                          // Eingabefeld leeren
                          const updatedNames = { ...newOptionNames };
                          updatedNames[categoryIndex] = '';
                          setNewOptionNames(updatedNames);
                        }
                      }}
                    >
                      Option
                    </Button>
                  </Box>
                  
                  {/* Unterkategorien anzeigen */}
                  {category.unterkategorien.map((subcategory, subcatIndex) => (
                    <Box key={`subcat-${categoryIndex}-${subcatIndex}-${subcategory.id}`} className="custom-subcategory">
                      <Box className="subcategory-header">
                        <Typography variant="subtitle1">{subcategory.name}</Typography>
                        <IconButton 
                          size="small"
                          onClick={() => deleteCustomSubcategory(categoryIndex, subcatIndex)}
                          className="delete-subcategory-button"
                        >
                          <ClearIcon />
                        </IconButton>
                      </Box>
                      
                      {/* Optionen der Unterkategorie */}
                      <Box ml={2}>
                        {subcategory.optionen.map((option, optIndex) => {
                          const isOptionCorrect = formData.richtige_elemente.includes(option.id);
                          
                          return (
                            <Box key={`option-${categoryIndex}-${subcatIndex}-${optIndex}-${option.id}`} 
                                 className={`custom-option ${isOptionCorrect ? 'correct' : ''}`}>
                              <Box display="flex" alignItems="center" justifyContent="space-between" width="100%">
                                <Typography>{option.name}</Typography>
                                <Box className="option-actions">
                                  <FormControlLabel
                                    control={
                                      <Checkbox 
                                        checked={isOptionCorrect}
                                        onChange={() => toggleCustomCorrectOption(option.id, option.name)}
                                        color="success"
                                        className="correct-checkbox"
                                      />
                                    }
                                    label={<Typography className="correct-option-label">Richtig</Typography>}
                                  />
                                  <IconButton 
                                    size="small"
                                    onClick={() => deleteCustomOption(categoryIndex, optIndex, subcatIndex)}
                                    className="delete-option-button"
                                  >
                                    <ClearIcon />
                                  </IconButton>
                                </Box>
                              </Box>
                            </Box>
                          );
                        })}
                      </Box>
                      
                      {/* Option zu Unterkategorie hinzufügen */}
                      <Box className="add-option-container" ml={2} mt={1}>
                        <TextField
                          placeholder="Neue Option für diese Unterkategorie"
                          variant="outlined"
                          size="small"
                          id={`subcat-option-input-${categoryIndex}-${subcatIndex}`}
                          value={newSubcatOptionNames[`${categoryIndex}-${subcatIndex}`] || ''}
                          onChange={(e) => {
                            const updatedNames = { ...newSubcatOptionNames };
                            updatedNames[`${categoryIndex}-${subcatIndex}`] = e.target.value;
                            setNewSubcatOptionNames(updatedNames);
                          }}
                          onKeyDown={(e) => {
                            if (e.key === 'Enter' && e.target.value.trim()) {
                              e.preventDefault();
                              e.stopPropagation();
                              // Generiere eine eindeutige ID für diesen spezifischen Aufruf
                              const uniqueCallId = `key-subcat-opt-${Date.now()}`;
                              addCustomSubcategoryOption(
                                categoryIndex, 
                                subcatIndex, 
                                newSubcatOptionNames[`${categoryIndex}-${subcatIndex}`],
                                uniqueCallId
                              );
                              
                              // Eingabefeld leeren
                              const updatedNames = { ...newSubcatOptionNames };
                              updatedNames[`${categoryIndex}-${subcatIndex}`] = '';
                              setNewSubcatOptionNames(updatedNames);
                            }
                          }}
                          onClick={(e) => e.stopPropagation()}
                        />
                        <Button
                          variant="text"
                          startIcon={<AddIcon />}
                          onClick={(e) => {
                            e.preventDefault();
                            e.stopPropagation();
                            if (newSubcatOptionNames[`${categoryIndex}-${subcatIndex}`]?.trim()) {
                              // Generiere eine eindeutige ID für diesen spezifischen Aufruf
                              const uniqueCallId = `btn-subcat-opt-${Date.now()}`;
                              addCustomSubcategoryOption(
                                categoryIndex, 
                                subcatIndex, 
                                newSubcatOptionNames[`${categoryIndex}-${subcatIndex}`],
                                uniqueCallId
                              );
                              
                              // Eingabefeld leeren
                              const updatedNames = { ...newSubcatOptionNames };
                              updatedNames[`${categoryIndex}-${subcatIndex}`] = '';
                              setNewSubcatOptionNames(updatedNames);
                            }
                          }}
                          size="small"
                        >
                          Option
                        </Button>
                      </Box>
                    </Box>
                  ))}
                  
                  {/* Direkte Optionen der Kategorie */}
                  {category.optionen.map((option, optIndex) => {
                    const isOptionCorrect = formData.richtige_elemente.includes(option.id);
                    
                    return (
                      <Box key={optIndex} className={`custom-option ${isOptionCorrect ? 'correct' : ''}`}>
                        <Box display="flex" alignItems="center" justifyContent="space-between" width="100%">
                          <Typography>{option.name}</Typography>
                          <Box className="option-actions">
                            <FormControlLabel
                              control={
                                <Checkbox 
                                  checked={isOptionCorrect}
                                  onChange={() => toggleCustomCorrectOption(option.id, option.name)}
                                  color="success"
                                  className="correct-checkbox"
                                />
                              }
                              label={<Typography className="correct-option-label">Richtig</Typography>}
                            />
                            <IconButton 
                              size="small"
                              onClick={() => deleteCustomOption(categoryIndex, optIndex)}
                              className="delete-option-button"
                            >
                              <ClearIcon />
                            </IconButton>
                          </Box>
                        </Box>
                      </Box>
                    );
                  })}
                </AccordionDetails>
              </Accordion>
            </Grid>
          ))}
          
          {/* Neue benutzerdefinierte Kategorie hinzufügen */}
          <Grid item xs={12} md={4}>
            {showCustomCategoryForm ? (
              <Paper className="add-category-form">
                <TextField
                  fullWidth
                  label="Kategorie-Name"
                  variant="outlined"
                  value={newCustomCategory}
                  onChange={(e) => setNewCustomCategory(e.target.value)}
                  onKeyDown={(e) => e.key === 'Enter' && addCustomCategory()}
                />
                <Box className="add-category-actions">
                  <Button 
                    variant="text" 
                    onClick={() => setShowCustomCategoryForm(false)}
                    startIcon={<ClearIcon />}
                  >
                    Abbrechen
                  </Button>
                  <Button 
                    variant="contained" 
                    color="primary" 
                    onClick={addCustomCategory}
                    startIcon={<AddIcon />}
                    disabled={!newCustomCategory.trim()}
                  >
                    Hinzufügen
                  </Button>
                </Box>
              </Paper>
            ) : (
              <Button
                variant="outlined"
                color="primary"
                className="add-category-button"
                startIcon={<AddCircleOutlineIcon />}
                onClick={() => setShowCustomCategoryForm(true)}
                fullWidth
              >
                Eigene Kategorie erstellen
              </Button>
            )}
          </Grid>
        </Grid>
      )}

      {/* Aktionsbuttons am Ende */}
      <Box className="bottom-action-buttons">
        <Button 
          variant="contained" 
          color="primary" 
          onClick={() => navigate(-1)}
          startIcon={<CancelIcon />}
        >
          Abbrechen
        </Button>
        <Button
          variant="contained"
          color="info"
          onClick={() => setQuizTestOpen(true)}
          startIcon={<PlayArrowIcon />}
          disabled={loading}
        >
          Quiz testen
        </Button>
        <Button 
          variant="contained" 
          color="success" 
          onClick={handleSubmit}
          startIcon={<SaveIcon />}
          disabled={loading}
        >
          Speichern
        </Button>
      </Box>

      {/* Image Zoom Dialog */}
      <Dialog 
        open={imageOpen} 
        onClose={() => setImageOpen(false)}
        maxWidth="xl"
        fullWidth
      >
        <IconButton 
          onClick={() => setImageOpen(false)}
          className="dialog-close-button"
        >
          <CloseIcon />
        </IconButton>
        <TransformWrapper
          initialScale={1}
          minScale={0.5}
          maxScale={8}
          wheel={{ step: 0.2 }}
          pinch={{ 
            enabled: true,
            step: 5,
            wheelEnabled: true,
            touchEnabled: true 
          }}
          doubleClick={{ enabled: true, mode: "reset" }}
          panning={{ enabled: true, touchPadEnabled: true }}
        >
          {({ zoomIn, zoomOut, resetTransform }) => (
            <>
              <Box className="zoom-controls">
                <IconButton onClick={() => zoomIn()}>
                  <ZoomInIcon />
                </IconButton>
                <IconButton onClick={() => zoomOut()}>
                  <ZoomOutIcon />
                </IconButton>
                <IconButton onClick={() => resetTransform()}>
                  <RestartAltIcon />
                </IconButton>
              </Box>
              <TransformComponent>
                <img 
                  src={formData.bild_url || placeholderImage} 
                  alt="EKG"
                  className="ekg-image-full"
                />
              </TransformComponent>
            </>
          )}
        </TransformWrapper>
      </Dialog>

      {/* Quiz Test Dialog */}
      <Dialog
        open={quizTestOpen}
        onClose={() => setQuizTestOpen(false)}
        maxWidth="lg"
        fullWidth
      >
        <DialogTitle>
          <Box display="flex" justifyContent="space-between" alignItems="center">
            <Typography variant="h6">Quiz Vorschau: {formData.name}</Typography>
            <IconButton onClick={() => setQuizTestOpen(false)}>
              <CloseIcon />
            </IconButton>
          </Box>
        </DialogTitle>
        <DialogContent sx={{ p: 0 }}>
          <EkgQuiz 
            quizData={formData}
            isTestMode={true}
          />
        </DialogContent>
      </Dialog>

      {/* Status Message Snackbar */}
      <Snackbar
        open={statusMessage.open}
        autoHideDuration={6000}
        onClose={() => setStatusMessage({...statusMessage, open: false})}
      >
        <Alert 
          onClose={() => setStatusMessage({...statusMessage, open: false})} 
          severity={statusMessage.severity}
          sx={{ width: '100%' }}
        >
          {statusMessage.message}
        </Alert>
      </Snackbar>
    </Box>
  );
}

export default EKG_QuizEditor;