import React, { useEffect, useState, useContext } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import axios from 'axios';
import { Container, Typography, Box } from '@mui/material';
import CourseNavigation from '../CourseNavigation'; // Sidebar importieren
import Termine from '../Termine'; // Optional: Termine importieren
import AuthContext from '../AuthContext';
import { ChevronLeft, ChevronRight } from '@mui/icons-material'; // Import Chevron Icons
import './KapitelPage.css'; // Importiere die CSS-Datei
import { QuizComponent } from './Quiz';
import EKG_Quiz from './EKG_Quiz';
import EKG_MultiQuiz from './EKG_MultiQuiz'; // Neue Imports

function KapitelPage() {
  const { kapitelId } = useParams();
  const [kapitel, setKapitel] = useState({ name: '', inhalte: [] });
  const [error, setError] = useState(null);
  const { user } = useContext(AuthContext);
  const [sidebarOpen, setSidebarOpen] = useState(false);
  const navigate = useNavigate(); // For navigating to the edit page
  
  useEffect(() => {
    if (kapitelId) {
      axios
        .get(`/api/kapitelMitInhalten/${kapitelId}`, { // GEÄNDERT: neue Route
          headers: { Authorization: `Bearer ${user.token}` },
        })
        .then((response) => {
          setKapitel({
            name: response.data.name,
            inhalte: response.data.inhalte || [] // Null-Prüfung hinzugefügt
          });
        })
        .catch((error) => {
          console.error('Fehler beim Abrufen des Kapitels:', error);
          setError('Kapitel oder Inhalt nicht gefunden.');
        });
    } else {
      setError('Keine Kapitel-ID vorhanden.');
    }
  }, [kapitelId, user.token]);

  // Direktes DOM-Styling nach dem Rendern
  useEffect(() => {
    if (kapitel.inhalte && kapitel.inhalte.length > 0) {
      // Kurzes Timeout, um sicherzustellen, dass der DOM aktualisiert wurde
      setTimeout(() => {
        // Alle Bilder in allen Text-Inhalten finden
        document.querySelectorAll('.kapitel-content img').forEach(img => {
          // Wenn das Bild ein width-Attribut hat, explizit anwenden
          if (img.hasAttribute('width')) {
            const width = img.getAttribute('width');
            // Breite direkt als style setzen mit !important
            img.style.setProperty('width', width, 'important');
            img.style.setProperty('max-width', width.endsWith('%') ? width : '100%', 'important');
          }
        });
      }, 100);
    }
  }, [kapitel]);

  const toggleSidebar = () => {
    setSidebarOpen(!sidebarOpen);
    const mainContent = document.querySelector('.main-content');
    if (sidebarOpen) {
      mainContent.classList.remove('shifted');
    } else {
      mainContent.classList.add('shifted');
    }
  };

  // Render-Funktion für verschiedene Inhaltstypen
  const renderInhalt = (inhalt) => {
    switch(inhalt.type) {
      case 'text':
        // Stark verbesserte Inhaltsprozessierung
        const processedContent = inhalt.content
          // Alle Bilder mit höchster Spezifität behandeln
          .replace(/<img([^>]*)>/g, (match, attrs) => {
            // Extrahiere width-Attribut wenn vorhanden
            const widthMatch = attrs.match(/width="([^"]*)"/);
            
            if (widthMatch) {
              const width = widthMatch[1];
              // Entferne das alte width-Attribut um Duplikate zu vermeiden
              const cleanedAttrs = attrs.replace(/width="[^"]*"/, '');
              
              // Füge inline style mit !important hinzu
              if (width.endsWith('%')) {
                return `<img${cleanedAttrs} style="width:${width} !important; max-width:${width} !important;" width="${width}">`;
              } else {
                // Wenn px oder nur Zahl
                return `<img${cleanedAttrs} style="width:${width}px !important; max-width:100% !important;" width="${width}px">`;
              }
            }
            
            // Wenn kein width-Attribut, lass es unverändert
            return match;
          });
        
        return (
          <div key={inhalt.id} className="text-content"
               dangerouslySetInnerHTML={{ __html: processedContent }} />
        );
      case 'ekgquiz':
        // Prüfen, ob es ein Multi-Quiz mit mehreren Fragen ist, mehr Debugging
        console.log('Prüfe Quiz-Konfiguration:', inhalt.content?.konfiguration);
        
        // Verbesserte Prüfung für Multi-Quiz
        const isMultiQuiz = inhalt.content && 
                            inhalt.content.konfiguration && 
                            ((inhalt.content.konfiguration.fragen_ids && inhalt.content.konfiguration.fragen_ids.length > 0) ||
                             (inhalt.content.konfiguration.fragen && inhalt.content.konfiguration.fragen.length > 0));
        
        if (isMultiQuiz) {
          console.log('Multi-Quiz erkannt mit IDs:', 
                      inhalt.content.konfiguration.fragen_ids || 
                      inhalt.content.konfiguration.fragen.map(f => f.id));
          
          // Stelle sicher, dass fragen_ids gesetzt ist, selbst wenn nur fragen vorhanden sind
          if (!inhalt.content.konfiguration.fragen_ids && inhalt.content.konfiguration.fragen) {
            inhalt.content.konfiguration.fragen_ids = inhalt.content.konfiguration.fragen.map(f => f.id);
          }
          
          return (
            <div key={inhalt.id} className="quiz-content">
              <EKG_MultiQuiz 
                config={inhalt.content.konfiguration}
                bildUrl={inhalt.content.bild_url}
              />
            </div>
          );
        } else {
          console.log('Einzelfragen-Quiz erkannt:', inhalt.content?.konfiguration);
          return (
            <div key={inhalt.id} className="quiz-content">
              <EKG_Quiz 
                config={inhalt.content.konfiguration}
                bildUrl={inhalt.content.bild_url}
              />
            </div>
          );
        }
      // Weitere Fälle für andere Inhaltstypen
      default:
        return <div key={inhalt.id}>Unbekannter Inhaltstyp</div>;
    }
  };

  if (error) return <div>{error}</div>;
  if (!kapitel.name) return <div>Lade...</div>;

  return (
    <div id="container" className={sidebarOpen ? 'sidebar-open' : ''}>
      <button className={`toggle-button ${sidebarOpen ? 'open' : ''}`} onClick={toggleSidebar}>
        {sidebarOpen ? <ChevronLeft /> : <ChevronRight />}
      </button>
      <nav>
        {/* Sidebar für Kurse und Kapitel */}
        <CourseNavigation sidebarOpen={sidebarOpen} />
      </nav>
      <main className={`main-content ${sidebarOpen ? 'shifted' : ''}`}>
        <Container>
          <div className="kapitel-header">
            <Typography variant="h2" component="h1" gutterBottom>
              {kapitel.name}
            </Typography>
            <Typography variant="h5" component="p" gutterBottom>
              Kapitelinhalt
            </Typography>
          </div>

          {/* Chapter Text */}
          <Box
            className="kapitel-content"
            sx={{
              marginBottom: '20px',
              padding: '10px',
              backgroundColor: 'var(--accent-color)',
              '& img': {
                height: 'auto !important', // Höhe immer auto
                maxWidth: '100% !important', // Maximale Breite = Container-Breite
                // Wichtig: KEINE width-Regel hier, damit die inline-Breite respektiert wird
                // Wenn keine width gesetzt ist, wird sie auf auto gesetzt
                width: 'auto',
                display: 'block',
                margin: '1em auto'
              },
              '& img[width]': {
                // Respektiere explizite width-Attribute, wenn vorhanden
                width: 'attr(width)'
              },
              '& img[style*="width"]': {
                // Respektiere inline-Stile mit width
                // KEIN !important hier, damit der inline-Stil Vorrang hat
              },
              '& video, & iframe': {
                maxWidth: '100%',
                height: 'auto',
                display: 'block',
                margin: '1em auto'
              }
            }}
          >
            {kapitel.inhalte && kapitel.inhalte.length > 0 ? (
              kapitel.inhalte.map(inhalt => renderInhalt(inhalt))
            ) : (
              <Typography variant="body1" sx={{ my: 3, textAlign: 'center' }}>
                Dieses Kapitel enthält noch keine Inhalte.
              </Typography>
            )}
          </Box>
        </Container>
      </main>

      {/* Optional: Termine oder andere Komponenten */}
      <aside id="kapitel-termine">
        <Termine />
      </aside>
    </div>
  );
}

export default KapitelPage;