import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import axios from 'axios';
import {
  Box,
  Typography,
  Paper,
  Button,
  IconButton,
  List,
  ListItem,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogContentText,
  DialogActions,
  TextField,
  Divider,
  Card,
  CardContent,
  Snackbar,
  Alert
} from '@mui/material';
import {
  Edit as EditIcon,
  Delete as DeleteIcon,
  Add as AddIcon,
  Visibility as VisibilityIcon,
  FileUpload as ImportIcon,
  PlayArrow as PlayArrowIcon
} from '@mui/icons-material';
import './EKG_QuizManager.css';
import EKG_MultiQuiz from './EKG_MultiQuiz';

function EKG_QuizManager({ config, bildUrl, editorMode = true, onChange, onImageChange }) {
  const navigate = useNavigate();
  const [quizConfig, setQuizConfig] = useState(config || { name: 'Neues EKG Quiz', fragen: [] });
  const [editNameOpen, setEditNameOpen] = useState(false);
  const [newName, setNewName] = useState(quizConfig.name || 'Neues EKG Quiz');
  const [confirmDeleteOpen, setConfirmDeleteOpen] = useState(false);
  const [confirmDeleteFrageId, setConfirmDeleteFrageId] = useState(null);
  const [loading, setLoading] = useState(false);
  const [statusMessage, setStatusMessage] = useState({
    open: false,
    message: '',
    severity: 'info'
  });
  const [previewMode, setPreviewMode] = useState(false);
  const [previewConfig, setPreviewConfig] = useState(null);

  useEffect(() => {
    const initializeQuiz = async () => {
      if (!config) {
        console.warn("Quiz-Konfiguration ist undefined oder null");
        return;
      }
      
      let safeConfig = config;
      if (typeof config === 'string') {
        try {
          safeConfig = JSON.parse(config);
          console.log("Config erfolgreich aus String geparst");
        } catch (error) {
          console.error("Fehler beim Parsen von config:", error);
          safeConfig = { name: 'EKG Quiz', fragen: [], fragen_ids: [] };
        }
      }
      
      safeConfig = {
        name: safeConfig.name || 'EKG Quiz',
        fragen: Array.isArray(safeConfig.fragen) ? safeConfig.fragen : [],
        fragen_ids: Array.isArray(safeConfig.fragen_ids) ? safeConfig.fragen_ids : [],
        quiz_id: safeConfig.quiz_id
      };
      
      if (JSON.stringify(quizConfig) !== JSON.stringify(safeConfig)) {
        setQuizConfig(safeConfig);
        console.log("Quiz-Konfiguration aktualisiert:", safeConfig);
      }
      
      try {
        if (safeConfig.quiz_id) {
          console.log("Lade Daten für Quiz mit ID:", safeConfig.quiz_id);
          
          const aktuelleFragenIds = safeConfig.fragen_ids || [];
          const aktuelleFragen = safeConfig.fragen || [];
          
          const quizResponse = await axios.get(`/api/ekgQuiz/${safeConfig.quiz_id}`);
          const quizData = quizResponse.data;
          
          let parsedQuizData = quizData;
          if (typeof quizData === 'string') {
            try {
              parsedQuizData = JSON.parse(quizData);
              console.log("Quiz-Daten erfolgreich aus String geparst");
            } catch (parseError) {
              console.error("Fehler beim Parsen der Quiz-Daten:", parseError);
              parsedQuizData = { fragen_ids: [] };
            }
          }
          
          let fragenIds = Array.isArray(parsedQuizData.fragen_ids) ? parsedQuizData.fragen_ids : [];
          console.log("Gefundene fragen_ids vom Server:", fragenIds);
          
          if (fragenIds.length === 0 && aktuelleFragenIds.length > 0) {
            console.log("Server hat keine fragen_ids, behalte lokale Daten:", aktuelleFragenIds);
            fragenIds = aktuelleFragenIds;
            
            if (safeConfig.quiz_id) {
              try {
                await axios.put(`/api/ekgQuiz/${safeConfig.quiz_id}`, {
                  name: safeConfig.name,
                  fragen_ids: aktuelleFragenIds
                });
                console.log("Quiz auf Server aktualisiert mit lokalen fragen_ids");
              } catch (updateError) {
                console.error("Fehler beim Aktualisieren des Quiz:", updateError);
              }
            }
          }
          
          const idsHaveChanged = JSON.stringify(aktuelleFragenIds) !== JSON.stringify(fragenIds);
          
          if (fragenIds.length > 0 && idsHaveChanged) {
            console.log("Lade Details für Fragen mit IDs:", fragenIds);
            
            const frageDaten = [];
            
            for (const frageId of fragenIds) {
              try {
                const lokaleFrage = aktuelleFragen.find(f => f.id === frageId);
                if (lokaleFrage) {
                  frageDaten.push(lokaleFrage);
                  continue;
                }
                
                const frageResponse = await axios.get(`/api/ekg-quizfragen/${frageId}`);
                let frageData = frageResponse.data;
                
                if (typeof frageData === 'string') {
                  try {
                    frageData = JSON.parse(frageData);
                  } catch (parseError) {
                    console.error(`Fehler beim Parsen der Frage mit ID ${frageId}:`, parseError);
                    continue;
                  }
                }
                
                frageDaten.push({
                  id: frageData.ecg_id || frageData.frage_id,
                  name: frageData.name,
                  bild_url: frageData.bild_url,
                  klappentext: frageData.klappentext
                });
              } catch (frageError) {
                console.warn(`Konnte Frage mit ID ${frageId} nicht laden:`, frageError);
              }
            }
            
            const updatedConfig = {
              ...safeConfig,
              fragen: frageDaten,
              fragen_ids: fragenIds
            };
            
            if (JSON.stringify(quizConfig) !== JSON.stringify(updatedConfig)) {
              console.log("Aktualisierte Quiz-Konfiguration mit Fragen:", updatedConfig);
              
              setQuizConfig(updatedConfig);
              if (onChange) {
                onChange(updatedConfig);
              }
            }
          }
        }
      } catch (error) {
        console.error("Fehler beim Initialisieren des Quiz:", error);
      }
    };
    
    initializeQuiz();
  }, [config]);
  
  const updateConfig = (newConfig) => {
    setQuizConfig(newConfig);
    if (onChange) {
      onChange(newConfig);
    }
  };
  
  const handleNameChange = async () => {
    if (!newName.trim()) return;
    
    const updatedConfig = {
      ...quizConfig,
      name: newName
    };
    
    updateConfig(updatedConfig);
    setEditNameOpen(false);
    
    if (quizConfig.quiz_id) {
      try {
        await axios.put(`/api/ekgQuiz/${quizConfig.quiz_id}`, {
          name: newName
        });
      } catch (error) {
        console.error('Fehler beim Aktualisieren des Quiz-Namens:', error);
      }
    }
  };
  
  const handleAddFrage = async () => {
    try {
      setLoading(true);
      
      const quizId = quizConfig.quiz_id;
      console.log("Erstelle Frage für Quiz mit ID:", quizId);
      
      const response = await axios.post('/api/ekg-quizfragen', {
        name: `Neue EKG Frage ${(quizConfig.fragen || []).length + 1}`,
        bild_url: '',
        klappentext: 'Beschreibung der Frage...',
        checked_elemente: {
          standard: { kategorien: [], unterkategorien: [], optionen: [] },
          custom: { kategorien: [], eigenstaendige_optionen: [] }
        },
        richtige_elemente: [],
        schwierigkeitsgrad: 'mittel',
        berechtigte_dozenten: [-1],
        quiz_id: quizId
      });
      
      if (!response.data || !response.data.frage_id) {
        throw new Error('Die Serverantwort enthält keine gültige Frage-ID');
      }
      
      const newFrage = {
        id: response.data.frage_id,
        name: `Neue EKG Frage ${(quizConfig.fragen || []).length + 1}`,
        bild_url: '',
        klappentext: 'Beschreibung der Frage...'
      };
      
      const updatedConfig = {
        ...quizConfig,
        fragen: [...(quizConfig.fragen || []), newFrage]
      };
      
      updatedConfig.fragen_ids = updatedConfig.fragen.map(frage => frage.id);
      
      updateConfig(updatedConfig);
      
      if (quizConfig.quiz_id) {
        await axios.put(`/api/ekgQuiz/${quizConfig.quiz_id}`, {
          name: updatedConfig.name,
          fragen_ids: updatedConfig.fragen_ids
        });
      }
      
      setLoading(false);
      
      alert('Neue EKG-Frage erfolgreich hinzugefügt!');
      
    } catch (error) {
      setLoading(false);
      console.error('Fehler beim Erstellen der EKG-Frage:', error);
      alert('Fehler beim Erstellen der EKG-Frage: ' + (error.response?.data?.message || error.message));
    }
  };
  
  const handleDeleteFrage = async (frageId) => {
    try {
      const updatedConfig = {
        ...quizConfig,
        fragen: (quizConfig.fragen || []).filter(frage => frage.id !== frageId),
        fragen_ids: (quizConfig.fragen_ids || []).filter(id => id !== frageId)
      };
      
      setConfirmDeleteOpen(false);
      setConfirmDeleteFrageId(null);
      
      updateConfig(updatedConfig);
      
      if (!frageId.toString().startsWith('temp-')) {
        await axios.delete(`/api/ekg-quizfragen/${frageId}`);
        
        setStatusMessage({
          open: true,
          message: 'EKG-Frage erfolgreich gelöscht',
          severity: 'success'
        });
      }
      
      if (quizConfig.quiz_id) {
        await axios.put(`/api/ekgQuiz/${quizConfig.quiz_id}`, {
          name: updatedConfig.name,
          fragen_ids: updatedConfig.fragen_ids
        });
      }
    } catch (error) {
      console.error('Fehler beim Löschen der EKG-Frage:', error);
      
      setStatusMessage({
        open: true,
        message: 'Fehler beim Löschen der EKG-Frage: ' + (error.response?.data?.message || error.message),
        severity: 'error'
      });
    }
  };
  
  const handleImportFrage = () => {
    alert('EKG-Import-Funktionalität wird später implementiert');
  };
  
  const handleEditFrage = (frageId) => {
    if (!frageId || typeof frageId !== 'string' && typeof frageId !== 'number') {
      console.error("Keine gültige Fragen-ID zum Bearbeiten vorhanden:", frageId);
      alert("Fehler: Diese Frage kann nicht bearbeitet werden (keine gültige ID vorhanden).");
      return;
    }
    
    console.log("Navigiere zum Editor für Frage:", frageId);
    navigate(`/ekg-quiz-editor/${frageId}`);
  };

  const handlePreviewQuiz = async () => {
    try {
      setLoading(true);
      
      const previewConfig = {
        quiz_id: quizConfig.quiz_id || 'preview',
        name: quizConfig.name || 'EKG-Quiz',
        fragen_ids: quizConfig.fragen.map(frage => frage.id)
      };
      
      setPreviewConfig(previewConfig);
      setPreviewMode(true);
      setLoading(false);
    } catch (error) {
      console.error('Fehler beim Vorbereiten der Vorschau:', error);
      setStatusMessage({
        open: true,
        message: 'Fehler beim Vorbereiten der Vorschau: ' + (error.message || 'Unbekannter Fehler'),
        severity: 'error'
      });
      setLoading(false);
    }
  };

  return (
    <Box className="ekg-quiz-manager" sx={{ border: '1px solid #eee', borderRadius: 2, p: 2, mb: 2 }}>
      <Paper elevation={2} className="quiz-header" sx={{ mb: 2, p: 2 }}>
        <Box display="flex" alignItems="center" justifyContent="space-between">
          <Typography variant="h5" className="quiz-title">
            {quizConfig.name || 'EKG Quiz'}
          </Typography>
          {editorMode && (
            <IconButton 
              onClick={() => {
                setNewName(quizConfig.name || 'EKG Quiz');
                setEditNameOpen(true);
              }}
              size="small"
            >
              <EditIcon />
            </IconButton>
          )}
        </Box>
      </Paper>

      {previewMode ? (
        <Box sx={{ mb: 2 }}>
          <Typography variant="h6" gutterBottom sx={{ mb: 2 }}>
            Quiz-Vorschau
          </Typography>
          <Paper elevation={1} sx={{ p: 2 }}>
            {quizConfig.fragen && quizConfig.fragen.length > 0 ? (
              <EKG_MultiQuiz 
                config={previewConfig}
                bildUrl={bildUrl}
              />
            ) : (
              <Typography color="textSecondary" sx={{ textAlign: 'center', py: 3 }}>
                Fügen Sie mindestens eine Frage hinzu, um eine Vorschau des Quiz zu sehen.
              </Typography>
            )}
          </Paper>
          
          <Box sx={{ mt: 2, display: 'flex', justifyContent: 'center' }}>
            <Button 
              variant="contained" 
              color="secondary"
              onClick={() => setPreviewMode(false)}
            >
              Zurück zum Editor
            </Button>
          </Box>
        </Box>
      ) : (
        <>
          <Paper elevation={1} sx={{ mb: 2, maxHeight: 300, overflow: 'auto' }}>
            <List>
              {(quizConfig.fragen || []).length > 0 ? (
                (quizConfig.fragen || []).map((frage, index) => (
                  <React.Fragment key={frage.id || index}>
                    {index > 0 && <Divider />}
                    <ListItem>
                      <Card sx={{ width: '100%' }}>
                        <CardContent>
                          <Box display="flex" justifyContent="space-between" alignItems="center">
                            <Typography variant="h6">{frage.name}</Typography>
                            {editorMode && (
                              <Box>
                                <IconButton 
                                  size="small" 
                                  color="primary"
                                  onClick={() => handleEditFrage(frage.id)}
                                >
                                  <EditIcon />
                                </IconButton>
                                <IconButton 
                                  size="small" 
                                  color="error"
                                  onClick={() => {
                                    setConfirmDeleteFrageId(frage.id);
                                    setConfirmDeleteOpen(true);
                                  }}
                                >
                                  <DeleteIcon />
                                </IconButton>
                              </Box>
                            )}
                          </Box>
                          {frage.klappentext && (
                            <Typography variant="body2" color="textSecondary" 
                              sx={{ mt: 1, whiteSpace: 'nowrap', overflow: 'hidden', textOverflow: 'ellipsis' }}>
                              {frage.klappentext}
                            </Typography>
                          )}
                        </CardContent>
                      </Card>
                    </ListItem>
                  </React.Fragment>
                ))
              ) : (
                <ListItem>
                  <Box sx={{ textAlign: 'center', width: '100%', py: 2 }}>
                    <Typography color="textSecondary">
                      Keine EKG-Fragen vorhanden. Fügen Sie EKG-Fragen mit dem Button unten hinzu.
                    </Typography>
                  </Box>
                </ListItem>
              )}
            </List>
          </Paper>
          
          <Box sx={{ display: 'flex', justifyContent: 'space-between', mt: 2 }}>
            <Box>
              <Button variant="contained" startIcon={<AddIcon />} onClick={handleAddFrage}>
                EKG hinzufügen
              </Button>
              <Button variant="outlined" startIcon={<ImportIcon />} onClick={handleImportFrage} sx={{ ml: 1 }}>
                EKG importieren
              </Button>
            </Box>
            
            <Button 
              variant="contained" 
              color="secondary"
              startIcon={<PlayArrowIcon />}
              onClick={handlePreviewQuiz}
              disabled={!quizConfig.fragen || quizConfig.fragen.length === 0}
            >
              Quiz-Vorschau starten
            </Button>
          </Box>
        </>
      )}

      <Dialog 
        open={editNameOpen} 
        onClose={() => setEditNameOpen(false)}
        aria-labelledby="quiz-name-dialog-title"
      >
        <DialogTitle id="quiz-name-dialog-title">Quiz-Namen bearbeiten</DialogTitle>
        <DialogContent>
          <TextField
            autoFocus
            margin="dense"
            label="Quiz-Name"
            type="text"
            fullWidth
            value={newName}
            onChange={(e) => setNewName(e.target.value)}
            inputProps={{ 
              autoComplete: "off"
            }}
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setEditNameOpen(false)}>
            Abbrechen
          </Button>
          <Button onClick={handleNameChange}>
            Speichern
          </Button>
        </DialogActions>
      </Dialog>

      <Dialog
        open={confirmDeleteOpen}
        onClose={() => {
          setConfirmDeleteOpen(false);
          setConfirmDeleteFrageId(null);
        }}
        aria-labelledby="delete-frage-dialog-title"
      >
        <DialogTitle id="delete-frage-dialog-title">Frage löschen?</DialogTitle>
        <DialogContent>
          <DialogContentText>
            Möchten Sie diese EKG-Frage wirklich löschen?
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => {
            setConfirmDeleteOpen(false);
            setConfirmDeleteFrageId(null);
          }}>
            Abbrechen
          </Button>
          <Button 
            onClick={() => handleDeleteFrage(confirmDeleteFrageId)} 
            color="error"
          >
            Löschen
          </Button>
        </DialogActions>
      </Dialog>

      <Snackbar
        open={statusMessage.open}
        autoHideDuration={6000}
        onClose={() => setStatusMessage({ ...statusMessage, open: false })}
      >
        <Alert 
          onClose={() => setStatusMessage({ ...statusMessage, open: false })} 
          severity={statusMessage.severity}
          sx={{ width: '100%' }}
        >
          {statusMessage.message}
        </Alert>
      </Snackbar>
    </Box>
  );
}

export default EKG_QuizManager;