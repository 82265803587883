import React, { useState, useEffect } from 'react';
import { 
  Box, 
  Typography, 
  Button, 
  Paper, 
  List, 
  ListItem, 
  ListItemText,
  Dialog,
  DialogTitle,
  DialogContent,
  IconButton,
  Divider,
  Card,
  CardContent,
  LinearProgress,
  ListItemIcon
} from '@mui/material';
import axios from 'axios';
import EkgQuiz from './EKG_Quiz';
import PlayArrowIcon from '@mui/icons-material/PlayArrow';
import SkipNextIcon from '@mui/icons-material/SkipNext';
import RestartAltIcon from '@mui/icons-material/RestartAlt';
import CloseIcon from '@mui/icons-material/Close';
import CheckCircleOutlineIcon from '@mui/icons-material/CheckCircleOutline';
import CancelIcon from '@mui/icons-material/Cancel';
import ErrorOutlineIcon from '@mui/icons-material/ErrorOutline';
import './EKG_MultiQuiz.css';

/**
 * Komponente für die Durchführung eines kompletten EKG-Quiz mit mehreren Fragen
 * 
 * @param {Object} props
 * @param {Object} props.config - Die Konfiguration des Quiz (enthält quiz_id und fragen_ids)
 * @param {string} props.bildUrl - Optional: URL zum Hauptbild des Quiz
 */
function EKG_MultiQuiz({ config, bildUrl }) {
  const [quizData, setQuizData] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [currentQuestionIndex, setCurrentQuestionIndex] = useState(0);
  const [quizResults, setQuizResults] = useState([]);
  const [showSummary, setShowSummary] = useState(false);
  const [quizStarted, setQuizStarted] = useState(false);
  const [quizCompleted, setQuizCompleted] = useState(false);
  const [currentQuestionData, setCurrentQuestionData] = useState(null);
  const [selectedItems, setSelectedItems] = useState([]);
  
  // Quiz-Daten laden
  useEffect(() => {
    const loadQuizData = async () => {
      try {
        if (!config || !config.quiz_id) {
          console.log("Keine quiz_id gefunden, prüfe config:", config);
          
          // Wenn keine quiz_id, aber fragen_ids vorhanden sind (Vorschaumodus)
          if (config && ((config.fragen_ids && config.fragen_ids.length > 0) || 
                         (config.fragen && config.fragen.length > 0))) {
            
            // Normalisieren
            const fragen_ids = config.fragen_ids || 
                              (config.fragen ? config.fragen.map(f => f.id).filter(Boolean) : []);
            
            console.log("Verwende fragen_ids aus config:", fragen_ids);
            
            setQuizData({
              name: config.name || 'EKG-Quiz',
              fragen_ids: fragen_ids,
              quiz_id: config.quiz_id || 'preview'
            });
            setLoading(false);
            return;
          }
          
          setError('Keine Quiz-ID oder Fragen gefunden');
          setLoading(false);
          return;
        }
        
        setLoading(true);
        const response = await axios.get(`/api/ekgQuiz/${config.quiz_id}`);
        let quizDataResponse = response.data;
        
        // Parsen, wenn es sich um einen String handelt
        if (typeof quizDataResponse === 'string') {
          try {
            quizDataResponse = JSON.parse(quizDataResponse);
          } catch (parseError) {
            console.error('Fehler beim Parsen der Quiz-Daten:', parseError);
            throw new Error('Ungültiges Quiz-Format');
          }
        }
        
        setQuizData(quizDataResponse);
        setLoading(false);
      } catch (error) {
        console.error('Fehler beim Laden des Quiz:', error);
        setError('Fehler beim Laden des Quiz: ' + 
                (error.response?.data?.message || error.message));
        setLoading(false);
      }
    };
    
    loadQuizData();
  }, [config]);
  
  // Quiz starten
  const startQuiz = async () => {
    // Prüfen, ob Fragen vorhanden sind
    if (!quizData || !quizData.fragen_ids || quizData.fragen_ids.length === 0) {
      setError('Dieses Quiz enthält keine Fragen.');
      return;
    }
    
    // Quiz-Status zurücksetzen
    setCurrentQuestionIndex(0);
    setQuizResults([]);
    setShowSummary(false);
    setQuizCompleted(false);
    setQuizStarted(true);
    
    // Erste Frage laden
    await loadQuestionData(0);
  };
  
  // Laden einer spezifischen Frage
  const loadQuestionData = async (index) => {
    if (!quizData || !quizData.fragen_ids || !quizData.fragen_ids[index]) {
      setError(`Keine Frage an Position ${index + 1} gefunden.`);
      return;
    }
    
    const frageId = quizData.fragen_ids[index];
    
    try {
      setLoading(true);
      
      // Frage mit den richtigen Antworten laden
      const response = await axios.get(`/api/ekg-quizfragen/${frageId}`);
      
      // 1. Verarbeite Antwort: String oder Objekt?
      let frageData = response.data;
      
      // 2. Prüfen, ob es ein String ist und ggf. parsen
      if (typeof frageData === 'string') {
        try {
          frageData = JSON.parse(frageData);
          console.log("Fragedaten erfolgreich geparst");
        } catch (parseError) {
          console.error('Fehler beim Parsen der Fragedaten:', parseError);
          throw new Error('Ungültiges Frageformat');
        }
      }
      
      // 3. Checked_elemente verarbeiten
      if (frageData.checked_elemente) {
        // Falls checked_elemente ein String ist, parsen
        if (typeof frageData.checked_elemente === 'string') {
          try {
            frageData.checked_elemente = JSON.parse(frageData.checked_elemente);
            console.log("Checked_elemente erfolgreich geparst");
          } catch (parseError) {
            console.error('Fehler beim Parsen der checked_elemente:', parseError);
            // Fallback: Leere Struktur erstellen
            frageData.checked_elemente = {
              standard: { kategorien: [] },
              custom: { kategorien: [] }
            };
          }
        }
      } else {
        // Fallback: Leere Struktur, wenn checked_elemente fehlt
        frageData.checked_elemente = {
          standard: { kategorien: [] },
          custom: { kategorien: [] }
        };
      }
      
      // 4. Sicherstellen, dass richtige_elemente ein Array ist
      if (!frageData.richtige_elemente) {
        frageData.richtige_elemente = [];
      } else if (typeof frageData.richtige_elemente === 'string') {
        try {
          frageData.richtige_elemente = JSON.parse(frageData.richtige_elemente);
          console.log("Richtige_elemente erfolgreich geparst");
        } catch (parseError) {
          console.error('Fehler beim Parsen der richtige_elemente:', parseError);
          frageData.richtige_elemente = [];
        }
      }
      
      // 5. Weitere Eigenschaften sicherstellen
      frageData.frage_id = frageData.frage_id || frageId;
      frageData.name = frageData.name || `Frage ${index + 1}`;
      
      console.log("Geladene und aufbereitete Fragedaten:", frageData);
      
      setCurrentQuestionData(frageData);
      setLoading(false);
    } catch (error) {
      console.error('Fehler beim Laden der Frage:', error);
      setLoading(false);
      setError('Fehler beim Laden der Frage: ' + 
              (error.response?.data?.message || error.message));
    }
  };
  
  // Funktion zum Speichern eines Quizergebnisses und Weitergehen zur nächsten Frage
  const handleQuizResult = (result) => {
    // Ergebnis speichern
    const updatedResults = [...quizResults, {
      ...result,
      questionName: currentQuestionData?.name || `Frage ${currentQuestionIndex + 1}`,
      questionId: quizData.fragen_ids[currentQuestionIndex]
    }];
    setQuizResults(updatedResults);
    
    // Überprüfen, ob wir am Ende des Quiz angelangt sind
    if (currentQuestionIndex + 1 >= quizData.fragen_ids.length) {
      // Quiz abgeschlossen - zeige Zusammenfassung
      setQuizCompleted(true);
      setShowSummary(true);
    }
  };
  
  // Zur nächsten Frage wechseln
  const handleNextQuestion = () => {
    if (currentQuestionIndex + 1 >= quizData.fragen_ids.length) {
      // Quiz abgeschlossen - zeige Zusammenfassung
      setQuizCompleted(true);
      setShowSummary(true);
    } else {
      // Lade die nächste Frage und setze die UI zurück
      const nextIndex = currentQuestionIndex + 1;
      setCurrentQuestionIndex(nextIndex);
      
      // Wichtig: Die QuizComponent vollständig zurücksetzen
      setSelectedItems([]); // Falls du einen gemeinsamen State hast
      loadQuestionData(nextIndex);
    }
  };
  
  // Quiz beenden
  const resetQuiz = () => {
    setQuizStarted(false);
    setQuizCompleted(false);
    setShowSummary(false);
    setCurrentQuestionData(null);
    setCurrentQuestionIndex(0);
    setQuizResults([]);
  };
  
  // Gesamtstatistik berechnen
  const calculateOverallStatistics = () => {
    if (!quizResults || quizResults.length === 0) return null;
    
    let totalCorrect = 0;
    let totalIncorrect = 0;
    let totalMissed = 0;
    let totalPossible = 0;
    
    quizResults.forEach(result => {
      totalCorrect += result.correctCount || 0;
      totalIncorrect += result.incorrectCount || 0;
      totalMissed += result.missedCount || 0;
      totalPossible += result.totalCorrect || 0;
    });
    
    const correctPercentage = totalPossible > 0 
      ? Math.round((totalCorrect / totalPossible) * 100) 
      : 0;
    
    // Ergebnisse pro Frage
    const questionResults = quizResults.map((result, index) => ({
      name: result.questionName || `Frage ${index + 1}`,
      correct: result.correctCount || 0,
      incorrect: result.incorrectCount || 0,
      missed: result.missedCount || 0,
      total: result.totalCorrect || 0,
      percentage: result.totalCorrect > 0 
        ? Math.round((result.correctCount / result.totalCorrect) * 100) 
        : 0
    }));
    
    return {
      totalCorrect,
      totalIncorrect,
      totalMissed,
      totalPossible,
      correctPercentage,
      questionResults,
      totalQuestions: quizResults.length
    };
  };
  
  // Füge diese Funktion ein
  const getDisplayNameForItem = (itemId) => {
    if (!itemId) return "Unbekannte Option";
    
    // Für Standard-Optionen
    if (itemId.startsWith('opt-')) {
      const parts = itemId.split('-');
      if (parts.length >= 3) {
        return parts.slice(2).join('-').replace(/-/g, ' ');
      }
    } 
    // Für benutzerdefinierte Optionen
    else if (itemId.startsWith('custom-')) {
      // Sicherheitsüberprüfungen für jede Ebene
      if (currentQuestionData && currentQuestionData.checked_elemente && currentQuestionData.checked_elemente.custom) {
        const customCategories = currentQuestionData.checked_elemente.custom.kategorien || [];
        
        for (const category of customCategories) {
          // Suche in direkten Optionen
          if (category.optionen && Array.isArray(category.optionen)) {
            const directOption = category.optionen.find(opt => opt && opt.id === itemId);
            if (directOption && directOption.name) return directOption.name;
          }
          
          // Suche in Unterkategorien
          if (category.unterkategorien && Array.isArray(category.unterkategorien)) {
            for (const subcategory of category.unterkategorien) {
              if (subcategory.optionen && Array.isArray(subcategory.optionen)) {
                const subcatOption = subcategory.optionen.find(opt => opt && opt.id === itemId);
                if (subcatOption && subcatOption.name) return subcatOption.name;
              }
            }
          }
        }
      }
    }
    // Fallback
    return `Option ${itemId.split('-').pop()}`;
  };

  // Zusammenfassung rendern
  const renderQuizSummary = () => {
    const stats = calculateOverallStatistics();
    
    if (!stats) return <Typography>Keine Ergebnisse verfügbar</Typography>;
    
    return (
      <Box className="quiz-summary" sx={{ p: 3 }}>
        <Typography variant="h4" gutterBottom sx={{ textAlign: 'center', mb: 3 }}>
          Quiz-Auswertung: {quizData?.name || "EKG-Quiz"}
        </Typography>
        
        <Box sx={{ mb: 4, textAlign: 'center' }}>
          <Typography variant="h5" gutterBottom>
            Gesamtergebnis: {stats.correctPercentage}%
          </Typography>
          <Typography variant="body1" color="text.secondary">
            {stats.totalCorrect} von {stats.totalPossible} Elementen korrekt identifiziert
          </Typography>
          
          <Box 
            sx={{ 
              mt: 2, 
              width: '100%', 
              height: '24px', 
              borderRadius: 1, 
              bgcolor: 'grey.200',
              overflow: 'hidden'
            }}
          >
            <Box 
              sx={{ 
                width: `${stats.correctPercentage}%`, 
                height: '100%', 
                bgcolor: stats.correctPercentage >= 80 ? 'success.main' : 
                         stats.correctPercentage >= 50 ? 'warning.main' : 'error.main',
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center'
              }}
            >
              <Typography variant="body2" color="white" fontWeight="bold">
                {stats.correctPercentage}%
              </Typography>
            </Box>
          </Box>
        </Box>
        
        {/* Statistik-Karten */}
        <Box 
          sx={{ 
            display: 'flex', 
            flexDirection: { xs: 'column', md: 'row' }, 
            gap: 2, 
            justifyContent: 'center',
            mb: 4 
          }}
        >
          <Card sx={{ flex: '1 1 auto', bgcolor: '#e8f5e9', minWidth: '120px' }}>
            <CardContent sx={{ textAlign: 'center' }}>
              <CheckCircleOutlineIcon color="success" fontSize="large" />
              <Typography variant="h6" color="success.main">
                {stats.totalCorrect}
              </Typography>
              <Typography variant="body2">Korrekt</Typography>
            </CardContent>
          </Card>
          
          <Card sx={{ flex: '1 1 auto', bgcolor: '#ffebee', minWidth: '120px' }}>
            <CardContent sx={{ textAlign: 'center' }}>
              <CancelIcon color="error" fontSize="large" />
              <Typography variant="h6" color="error.main">
                {stats.totalIncorrect}
              </Typography>
              <Typography variant="body2">Falsch</Typography>
            </CardContent>
          </Card>
          
          <Card sx={{ flex: '1 1 auto', bgcolor: '#fff8e1', minWidth: '120px' }}>
            <CardContent sx={{ textAlign: 'center' }}>
              <ErrorOutlineIcon color="warning" fontSize="large" />
              <Typography variant="h6" color="warning.main">
                {stats.totalMissed}
              </Typography>
              <Typography variant="body2">Verpasst</Typography>
            </CardContent>
          </Card>
        </Box>
        
        {/* Liste der einzelnen Fragen und Ergebnisse */}
        <Box sx={{ mb: 3 }}>
          <Typography variant="h6" gutterBottom>
            Detaillierte Ergebnisse
          </Typography>
          
          <List>
            {stats.questionResults.map((item, index) => (
              <ListItem key={index} divider={index < stats.questionResults.length - 1}>
                <Box sx={{ width: '100%' }}>
                  <Box display="flex" justifyContent="space-between" alignItems="center">
                    <Typography variant="subtitle1">{item.name}</Typography>
                    <Typography 
                      variant="subtitle1" 
                      color={
                        item.percentage >= 80 ? 'success.main' : 
                        item.percentage >= 50 ? 'warning.main' : 'error.main'
                      }
                    >
                      {item.percentage}%
                    </Typography>
                  </Box>
                  
                  <Box sx={{ width: '100%', mt: 1 }}>
                    <LinearProgress 
                      variant="determinate" 
                      value={item.percentage} 
                      color={
                        item.percentage >= 80 ? "success" : 
                        item.percentage >= 50 ? "warning" : "error"
                      }
                      sx={{ height: 10, borderRadius: 5 }}
                    />
                  </Box>
                  
                  <Box display="flex" mt={2} gap={2}>
                    <Box flex={1}>
                      <Typography variant="body2" color="success.main">
                        Korrekt: {item.correct} von {item.total}
                      </Typography>
                    </Box>
                    <Box flex={1}>
                      <Typography variant="body2" color="error.main">
                        Falsch: {item.incorrect}
                      </Typography>
                    </Box>
                    <Box flex={1}>
                      <Typography variant="body2" color="warning.main">
                        Verpasst: {item.missed}
                      </Typography>
                    </Box>
                  </Box>
                  
                  <Box>
                    <Typography variant="body2" color="success.main">
                      Korrekt ausgewählt:
                    </Typography>
                    <List dense>
                      {quizResults[index]?.correctSelections?.map((answerItem, idx) => (
                        <ListItem key={`correct-${idx}`} dense>
                          <ListItemIcon>
                            <CheckCircleOutlineIcon color="success" fontSize="small" />
                          </ListItemIcon>
                          <ListItemText primary={getDisplayNameForItem(answerItem)} />
                        </ListItem>
                      ))}
                    </List>
                  </Box>
                </Box>
              </ListItem>
            ))}
          </List>
        </Box>
        
        <Box sx={{ display: 'flex', justifyContent: 'center', mt: 4 }}>
          <Button 
            variant="contained" 
            color="primary" 
            onClick={resetQuiz}
            startIcon={<RestartAltIcon />}
          >
            Quiz erneut starten
          </Button>
        </Box>
      </Box>
    );
  };
  
  // Einzelne Frage rendern
  const renderQuestion = () => {
    return (
      <>
        <Box sx={{ mb: 2, display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
          <Typography variant="h6">
            Frage {currentQuestionIndex + 1} von {quizData?.fragen_ids?.length || 0}
          </Typography>
          
          {quizResults.length > currentQuestionIndex && (
            <Button 
              variant="contained" 
              color="primary"
              onClick={handleNextQuestion}
              endIcon={<SkipNextIcon />}
              disabled={currentQuestionIndex + 1 >= quizData.fragen_ids.length}
            >
              {currentQuestionIndex + 1 >= quizData.fragen_ids.length ? 
                "Auswertung anzeigen" : "Nächste Frage"}
            </Button>
          )}
        </Box>
        
        {/* Wichtig: Für jede Frage eine neue Quiz-Instanz erstellen mit key={currentQuestionIndex} */}
        <EkgQuiz 
          key={`question-${currentQuestionIndex}-${currentQuestionData?.frage_id || ''}`}
          quizData={currentQuestionData}
          isTestMode={true}
          onQuizResult={handleQuizResult}
          disableRetry={true}
        />
      </>
    );
  };
  
  // Startbildschirm rendern
  const renderStartScreen = () => {
    return (
      <Box 
        sx={{ 
          display: 'flex', 
          flexDirection: 'column', 
          alignItems: 'center', 
          justifyContent: 'center',
          py: 6,
          px: 3,
          textAlign: 'center',
          backgroundColor: 'white',
          borderRadius: '6px',
          boxShadow: '0 2px 8px rgba(0,0,0,0.05)'
        }}
      >
        <Typography variant="h4" gutterBottom>
          {quizData?.name || 'EKG-Quiz'}
        </Typography>
        
        {bildUrl && (
          <Box 
            component="img" 
            src={bildUrl} 
            alt="Quiz" 
            sx={{ 
              maxWidth: '100%', 
              maxHeight: 300, 
              objectFit: 'contain',
              mb: 4 
            }} 
          />
        )}
        
        <Typography variant="body1" paragraph>
          Dieses Quiz besteht aus {quizData?.fragen_ids?.length || 0} Fragen.
          Bei jeder Frage musst du relevante EKG-Merkmale identifizieren.
        </Typography>
        
        <Typography variant="body1" paragraph>
          Nach jeder Frage erhältst du eine detaillierte Auswertung deiner Antworten.
          Am Ende des Quiz bekommst du eine Gesamtübersicht deiner Ergebnisse.
        </Typography>
        
        <Button 
          variant="contained" 
          color="primary" 
          size="large" 
          startIcon={<PlayArrowIcon />} 
          onClick={startQuiz}
          sx={{ 
            mt: 2,
            backgroundColor: 'var(--primary-color, #4285f4)',
            '&:hover': {
              backgroundColor: 'var(--primary-dark, #3367d6)'
            }
          }}
          disabled={loading || !quizData || !quizData.fragen_ids || quizData.fragen_ids.length === 0}
        >
          Quiz starten
        </Button>
      </Box>
    );
  };
  
  if (loading && !quizStarted) {
    return (
      <Box sx={{ textAlign: 'center', p: 4 }}>
        <Typography>Quiz wird geladen...</Typography>
      </Box>
    );
  }
  
  if (error) {
    return (
      <Box sx={{ textAlign: 'center', p: 4 }}>
        <Typography color="error">{error}</Typography>
      </Box>
    );
  }

  return (
    <Box className="ekg-multi-quiz">
      <Paper elevation={3} sx={{ 
        p: 2, 
        mb: 2, 
        backgroundColor: 'var(--accent-color, #f7f9fc)',
        borderRadius: '8px',
        border: '1px solid #e0e0e0'
      }}>
        {!quizStarted ? renderStartScreen() : 
         showSummary ? renderQuizSummary() : 
         renderQuestion()}
      </Paper>
    </Box>
  );
}

export default EKG_MultiQuiz;