// src/theme.js
import { createTheme } from '@mui/material/styles';

const theme = createTheme({
  palette: {
    primary: {
      main: '#012B66', // --primary-color
    },
    secondary: {
      main: '#9CB8E7', // --accent-color:
    },
    background: {
      default: '#BED0EF', // --secondary-color
      paper: '#f8f2f2', // --custom-white für Dialog-Hintergründe
    },
    text: {
      primary: '#333333', // schwarz
    },
  },
  components: {
    // Dialog-Hintergrund anpassen
    MuiBackdrop: {
      styleOverrides: {
        root: {
          backgroundColor: 'rgba(190, 208, 239, 0.7)', // --secondary-color mit Transparenz
        },
      },
    },
    // Dialog-Papier anpassen
    MuiDialog: {
      styleOverrides: {
        paper: {
          backgroundColor: '#f8f2f2', // --custom-white
        },
      },
    },
    // Andere MUI-Komponenten falls nötig
  },
});

export default theme;
