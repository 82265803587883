import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { 
  Typography, 
  Accordion, 
  AccordionSummary, 
  AccordionDetails,
  Box,
  Button,
  Grid,
  Paper,
  List,
  ListItem,
  ListItemText,
  IconButton,
  Dialog,
  Collapse,
  Alert,
  AlertTitle,
  FormControlLabel,
  Checkbox,
  DialogTitle,
  DialogContent,
  DialogActions,
  Divider,
  ListItemIcon,
  Tooltip
} from '@mui/material';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import DeleteIcon from '@mui/icons-material/Delete';
import ZoomInIcon from '@mui/icons-material/ZoomIn';
import ZoomOutIcon from '@mui/icons-material/ZoomOut';
import RestartAltIcon from '@mui/icons-material/RestartAlt';
import CloseIcon from '@mui/icons-material/Close';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import CheckCircleOutlineIcon from '@mui/icons-material/CheckCircleOutline';
import CancelIcon from '@mui/icons-material/Cancel';
import ErrorOutlineIcon from '@mui/icons-material/ErrorOutline';
import './EKG_Quiz.css';
import ekgImage from '../assets/EKG_example.png';
import { TransformWrapper, TransformComponent } from "react-zoom-pan-pinch";

function EkgQuiz({ 
  config, 
  bildUrl, 
  quizData: initialQuizData, 
  isTestMode = false,
  onQuizResult, // Callback für Quiz-Ergebnisse
  disableRetry = false // Option zur Deaktivierung des "Erneut versuchen" Buttons
}) {
  const [ekgDiagnostik, setEkgDiagnostik] = useState({});
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [selectedItems, setSelectedItems] = useState([]);
  const [imageOpen, setImageOpen] = useState(false);
  const [textExpanded, setTextExpanded] = useState(false);
  const [quizResult, setQuizResult] = useState(null);
  const [feedbackDialogOpen, setFeedbackDialogOpen] = useState(false);
  
  // Hinzufügen des fehlenden quizData-States
  const [quizData, setQuizData] = useState(initialQuizData);

  // Am Anfang der Komponente: Verbessern Sie die Datenverarbeitung
  useEffect(() => {
    // Stelle sicher, dass quizData eine minimale gültige Struktur hat
    if (initialQuizData) {
      let processedData = { ...initialQuizData };
      
      // Überprüfen und Verarbeiten von checked_elemente
      if (!processedData.checked_elemente) {
        processedData.checked_elemente = {
          standard: { kategorien: [] },
          custom: { kategorien: [] }
        };
      } else if (typeof processedData.checked_elemente === 'string') {
        try {
          processedData.checked_elemente = JSON.parse(processedData.checked_elemente);
        } catch (e) {
          console.error('Fehler beim Parsen von checked_elemente:', e);
          processedData.checked_elemente = {
            standard: { kategorien: [] },
            custom: { kategorien: [] }
          };
        }
      }
      
      // Stelle sicher, dass standard und custom vorhanden sind
      if (!processedData.checked_elemente.standard) {
        processedData.checked_elemente.standard = { kategorien: [] };
      }
      if (!processedData.checked_elemente.custom) {
        processedData.checked_elemente.custom = { kategorien: [] };
      }
      
      // Überprüfen und Verarbeiten von richtige_elemente
      if (!processedData.richtige_elemente) {
        processedData.richtige_elemente = [];
      } else if (typeof processedData.richtige_elemente === 'string') {
        try {
          processedData.richtige_elemente = JSON.parse(processedData.richtige_elemente);
        } catch (e) {
          console.error('Fehler beim Parsen von richtige_elemente:', e);
          processedData.richtige_elemente = [];
        }
      }
      
      console.log("Aufbereitete Quiz-Daten:", processedData);
      setQuizData(processedData);
    }
  }, [initialQuizData]);
  
  // Nutze dynamische Daten aus quizData, wenn im Testmodus
  const quizName = isTestMode ? (quizData?.name || "EKG-Quiz") : (config?.name || "EKG-Quiz");
  const description = isTestMode ? (quizData?.klappentext || "") : (config?.description || "");
  const image = isTestMode ? (quizData?.bild_url || ekgImage) : (bildUrl || ekgImage);
  
  // Füge folgende Funktion ein, um eine einzelne Frage zu laden
  const loadQuizQuestion = async (questionId) => {
    try {
      setLoading(true);
      setError(null);
      
      // Ändere den Endpunkt zu /api/ekg-quizfragen/
      const response = await axios.get(`/api/ekg-quizfragen/${questionId}`);
      
      // Setze die Daten
      setQuizData(response.data);
      setSelectedItems([]);
      setQuizResult(null);
      setLoading(false);
    } catch (err) {
      console.error('Fehler beim Laden der Quizfrage:', err);
      setError('Die Quizfrage konnte nicht geladen werden: ' + 
              (err.response?.data?.message || err.message));
      setLoading(false);
    }
  };

  // Daten aus der API laden
  useEffect(() => {
    const fetchEkgData = async () => {
      try {
        setLoading(true);
        const response = await axios.get('/api/ekg-diagnostik');
        setEkgDiagnostik(response.data);
        setError(null);
      } catch (err) {
        console.error('Fehler beim Laden der EKG-Daten:', err);
        setError('Die EKG-Daten konnten nicht geladen werden.');
      } finally {
        setLoading(false);
      }
    };

    fetchEkgData();
  }, []);

  // Im useEffect für externe Quizfragen (neuer Effekt hinzufügen)
  useEffect(() => {
    // Wenn frageId von außen übergeben wird und keine Testvorschau ist
    if (!isTestMode && config?.frage_id) {
      loadQuizQuestion(config.frage_id);
    }
  }, [isTestMode, config?.frage_id]);

  const addItem = (item) => {
    if (!selectedItems.includes(item)) {
      setSelectedItems([...selectedItems, item]);
    }
  };

  const removeItem = (index) => {
    setSelectedItems(selectedItems.filter((_, i) => i !== index));
  };

  const clearAll = () => {
    setSelectedItems([]);
  };

  const handleSubmit = async () => {
    if (!isTestMode) {
      // Reguläres Quiz: Sende Antworten an den Server zur Überprüfung
      try {
        setLoading(true);
        
        // Stelle sicher, dass wir eine Frage-ID haben
        const frageId = quizData?.frage_id || config?.frage_id;
        
        if (!frageId) {
          console.error('Keine Frage-ID vorhanden');
          setError('Fehler: Keine Frage-ID vorhanden');
          setLoading(false);
          return;
        }
        
        // Sende Antworten an den Server
        const response = await axios.post('/api/quiz-check-answer', {
          frageId,
          selectedAnswers: selectedItems
        });
        
        // Setze Ergebnis und öffne Feedback-Dialog
        setQuizResult(response.data);
        setFeedbackDialogOpen(true);
        setLoading(false);

        // Callback aufrufen, falls vorhanden
        if (onQuizResult) {
          onQuizResult(response.data);
        }
      } catch (err) {
        console.error('Fehler bei der Überprüfung der Antworten:', err);
        setError('Fehler bei der Überprüfung der Antworten: ' + 
                (err.response?.data?.message || err.message));
        setLoading(false);
      }
      return;
    }

    // Testmodus (Editor-Vorschau): Lokale Überprüfung wie bisher
    const correctAnswers = quizData?.richtige_elemente || [];
    
    // Sicherstellen, dass alle Arrays initialisiert sind
    const userSelections = selectedItems || [];
    
    // Berechne Ergebnis
    const correctSelections = userSelections.filter(item => correctAnswers.includes(item));
    const incorrectSelections = userSelections.filter(item => !correctAnswers.includes(item));
    const missedAnswers = correctAnswers.filter(item => !userSelections.includes(item));
    
    // Details für Feedback-Dialog
    const resultDetails = {
      correctCount: correctSelections.length,
      incorrectCount: incorrectSelections.length,
      missedCount: missedAnswers.length,
      totalCorrect: correctAnswers.length,
      correctPercentage: correctAnswers.length > 0 
        ? Math.round((correctSelections.length / correctAnswers.length) * 100) 
        : 0,
      correctSelections,
      incorrectSelections,
      missedAnswers,
      correctAnswers,
      allCorrectAnswers: correctAnswers
    };
    
    // Ergebnis setzen und Feedback-Dialog öffnen
    setQuizResult(resultDetails);
    setFeedbackDialogOpen(true);

    // Callback aufrufen, falls vorhanden
    if (onQuizResult) {
      onQuizResult(resultDetails);
    }
  };
  
  const handleCloseFeedbackDialog = () => {
    setFeedbackDialogOpen(false);
  };
  
  const handleRetry = () => {
    setSelectedItems([]);
    setQuizResult(null);
    setFeedbackDialogOpen(false);
  };
  
  const getDisplayNameForItem = (itemId) => {
    if (!itemId) return "Unbekannte Option";
    
    // Für Standard-Optionen
    if (itemId.startsWith('opt-')) {
      const parts = itemId.split('-');
      if (parts.length >= 3) {
        return parts.slice(2).join('-').replace(/-/g, ' ');
      }
    } 
    // Für benutzerdefinierte Optionen
    else if (itemId.startsWith('custom-')) {
      // Sicherheitsüberprüfungen für jede Ebene
      if (isTestMode && quizData && quizData.checked_elemente && quizData.checked_elemente.custom) {
        const customCategories = quizData.checked_elemente.custom.kategorien || [];
        
        for (const category of customCategories) {
          // Suche in direkten Optionen
          if (category.optionen && Array.isArray(category.optionen)) {
            const directOption = category.optionen.find(opt => opt && opt.id === itemId);
            if (directOption && directOption.name) return directOption.name;
          }
          
          // Suche in Unterkategorien
          if (category.unterkategorien && Array.isArray(category.unterkategorien)) {
            for (const subcategory of category.unterkategorien) {
              if (subcategory.optionen && Array.isArray(subcategory.optionen)) {
                const subcatOption = subcategory.optionen.find(opt => opt && opt.id === itemId);
                if (subcatOption && subcatOption.name) return subcatOption.name;
              }
            }
          }
        }
      }
    }
    // Fallback
    return `Option ${itemId.split('-').pop()}`;
  };

  const toggleTextExpand = () => {
    setTextExpanded(!textExpanded);
  };

  const renderStandardCategories = () => {
    if (!isTestMode || !quizData) return renderAllCategories();
    
    // Im Testmodus: Nur ausgewählte Kategorien anzeigen
    const standardKategorien = quizData?.checked_elemente?.standard?.kategorien || [];
    const { unterkategorien = [], optionen = [] } = quizData.checked_elemente?.standard || {};
    
    return Object.entries(ekgDiagnostik).map(([category, subcategories], index) => {
      const categoryId = category.toLowerCase().replace(/\s+/g, '-');
      
      // Nur ausgewählte Kategorien anzeigen
      if (!standardKategorien.includes(categoryId)) return null;
      
      return (
        <Grid item xs={12} md={4} key={`standard-${index}`}>
          <Accordion>
            <AccordionSummary expandIcon={<ExpandMoreIcon />}>
              <Typography>{category}</Typography>
            </AccordionSummary>
            <AccordionDetails>
              {renderStandardSubcategories(category, subcategories, unterkategorien, optionen)}
            </AccordionDetails>
          </Accordion>
        </Grid>
      );
    });
  };

  const renderStandardSubcategories = (category, subcategories, selectedUnterkategorien, selectedOptionen) => {
    const categoryId = category.toLowerCase().replace(/\s+/g, '-');
    
    // Fall: subcategories ist ein Array von Strings (direkte Optionen unter einer Kategorie)
    if (Array.isArray(subcategories)) {
      return subcategories.map((item, i) => {
        const optionId = `${categoryId}-${item.toLowerCase().replace(/\s+/g, '-')}`;
        
        // Nur ausgewählte Optionen anzeigen im Testmodus
        if (isTestMode && !selectedOptionen.includes(optionId)) return null;
        
        const fullOptionId = `opt-${optionId}`;
        const isSelected = selectedItems.includes(fullOptionId);
        const isCorrect = quizResult && quizResult.allCorrectAnswers && quizResult.allCorrectAnswers.includes(fullOptionId);
        const isWrong = quizResult && isSelected && !isCorrect;
        
        return (
          <Box key={i} className={`quiz-option ${isCorrect ? 'correct' : ''} ${isWrong ? 'wrong' : ''}`}>
            <FormControlLabel
              control={
                <Checkbox 
                  checked={isSelected}
                  onChange={(e) => {
                    if (e.target.checked) {
                      addItem(fullOptionId);
                    } else {
                      const index = selectedItems.indexOf(fullOptionId);
                      if (index !== -1) removeItem(index);
                    }
                  }}
                  disabled={quizResult !== null}
                />
              }
              label={<Typography>{item}</Typography>}
            />
            {quizResult && isCorrect && <span className="correct-indicator">✓</span>}
            {quizResult && isWrong && <span className="wrong-indicator">✗</span>}
          </Box>
        );
      });
    }

    // Fall: subcategories ist ein Objekt mit Unterkategorien
    return Object.entries(subcategories).map(([subcat, items]) => {
      const subcatId = `${categoryId}-${subcat.toLowerCase().replace(/\s+/g, '-')}`;
      
      // Nur ausgewählte Unterkategorien anzeigen im Testmodus
      if (isTestMode && !selectedUnterkategorien.includes(subcatId)) return null;
      
      return (
        <Box key={subcat} mb={2}>
          <Typography variant="subtitle1">{subcat}</Typography>
          
          <Box ml={4}>
            {Array.isArray(items) && items.map((item, i) => {
              const optionId = `${subcatId}-${item.toLowerCase().replace(/\s+/g, '-')}`;
              
              // Nur ausgewählte Optionen anzeigen im Testmodus
              if (isTestMode && !selectedOptionen.includes(optionId)) return null;
              
              const fullOptionId = `opt-${optionId}`;
              const isSelected = selectedItems.includes(fullOptionId);
              const isCorrect = quizResult && quizResult.allCorrectAnswers && quizResult.allCorrectAnswers.includes(fullOptionId);
              const isWrong = quizResult && isSelected && !isCorrect;
              
              return (
                <Box key={i} className={`quiz-option ${isCorrect ? 'correct' : ''} ${isWrong ? 'wrong' : ''}`}>
                  <FormControlLabel
                    control={
                      <Checkbox 
                        checked={isSelected}
                        onChange={(e) => {
                          if (e.target.checked) {
                            addItem(fullOptionId);
                          } else {
                            const index = selectedItems.indexOf(fullOptionId);
                            if (index !== -1) removeItem(index);
                          }
                        }}
                        disabled={quizResult !== null}
                      />
                    }
                    label={<Typography>{item}</Typography>}
                  />
                  {quizResult && isCorrect && <span className="correct-indicator">✓</span>}
                  {quizResult && isWrong && <span className="wrong-indicator">✗</span>}
                </Box>
              );
            })}
          </Box>
        </Box>
      );
    });
  };

  const renderCustomCategories = () => {
    if (!isTestMode || !quizData || !quizData.checked_elemente?.custom?.kategorien?.length) return null;
    
    const customKategorien = quizData.checked_elemente?.custom?.kategorien || [];
    
    return customKategorien.map((category, index) => (
      <Grid item xs={12} md={4} key={`custom-${index}`}>
        <Accordion>
          <AccordionSummary expandIcon={<ExpandMoreIcon />}>
            <Typography>{category.name}</Typography>
          </AccordionSummary>
          <AccordionDetails>
            {/* Direkte Optionen der Kategorie */}
            {category.optionen.map((option, optIndex) => {
              const isSelected = selectedItems.includes(option.id);
              const isCorrect = quizResult && quizResult.allCorrectAnswers && quizResult.allCorrectAnswers.includes(option.id);
              const isWrong = quizResult && isSelected && !isCorrect;
              
              return (
                <Box key={optIndex} className={`quiz-option ${isCorrect ? 'correct' : ''} ${isWrong ? 'wrong' : ''}`}>
                  <FormControlLabel
                    control={
                      <Checkbox 
                        checked={isSelected}
                        onChange={(e) => {
                          if (e.target.checked) {
                            addItem(option.id);
                          } else {
                            const index = selectedItems.indexOf(option.id);
                            if (index !== -1) removeItem(index);
                          }
                        }}
                        disabled={quizResult !== null}
                      />
                    }
                    label={<Typography>{option.name}</Typography>}
                  />
                  {quizResult && isCorrect && <span className="correct-indicator">✓</span>}
                  {quizResult && isWrong && <span className="wrong-indicator">✗</span>}
                </Box>
              );
            })}
            
            {/* Unterkategorien mit ihren Optionen */}
            {category.unterkategorien.map((subcategory, subcatIndex) => (
              <Box key={`subcat-${subcatIndex}`} mb={2}>
                <Typography variant="subtitle1">{subcategory.name}</Typography>
                
                <Box ml={4}>
                  {subcategory.optionen.map((option, optIndex) => {
                    const isSelected = selectedItems.includes(option.id);
                    const isCorrect = quizResult && quizResult.allCorrectAnswers && quizResult.allCorrectAnswers.includes(option.id);
                    const isWrong = quizResult && isSelected && !isCorrect;
                    
                    return (
                      <Box key={`option-${optIndex}`} className={`quiz-option ${isCorrect ? 'correct' : ''} ${isWrong ? 'wrong' : ''}`}>
                        <FormControlLabel
                          control={
                            <Checkbox 
                              checked={isSelected}
                              onChange={(e) => {
                                if (e.target.checked) {
                                  addItem(option.id);
                                } else {
                                  const index = selectedItems.indexOf(option.id);
                                  if (index !== -1) removeItem(index);
                                }
                              }}
                              disabled={quizResult !== null}
                            />
                          }
                          label={<Typography>{option.name}</Typography>}
                        />
                        {quizResult && isCorrect && <span className="correct-indicator">✓</span>}
                        {quizResult && isWrong && <span className="wrong-indicator">✗</span>}
                      </Box>
                    );
                  })}
                </Box>
              </Box>
            ))}
          </AccordionDetails>
        </Accordion>
      </Grid>
    ));
  };

  // Normale Anzeige aller Kategorien (für den Nicht-Testmodus)
  const renderAllCategories = () => {
    return Object.entries(ekgDiagnostik).map(([category, subcategories], index) => (
      <Grid item xs={12} md={4} key={index}>
        <Accordion>
          <AccordionSummary expandIcon={<ExpandMoreIcon />}>
            <Typography>{category}</Typography>
          </AccordionSummary>
          <AccordionDetails>
            {renderSubcategories(subcategories)}
          </AccordionDetails>
        </Accordion>
      </Grid>
    ));
  };

  // Original-Methode zum Rendern von Unterkategorien beibehalten
  const renderSubcategories = (subcategories) => {
    if (Array.isArray(subcategories)) {
      // Handle array of items directly
      return subcategories.map((item, i) => {
        const itemId = `option-${i}`;
        return (
          <Button 
            key={i}
            variant="text"
            onClick={() => addItem(itemId)}
            fullWidth
          >
            {item}
          </Button>
        );
      });
    }

    // Handle object with nested categories
    return Object.entries(subcategories).map(([subcat, items]) => (
      <Box key={subcat}>
        <Typography variant="subtitle1">{subcat}</Typography>
        {Array.isArray(items) && items.length > 0 && items.map((item, i) => {
          const itemId = `${subcat}-option-${i}`;
          return (
            <Button 
              key={i}
              variant="text"
              onClick={() => addItem(itemId)}
              fullWidth
            >
              {item}
            </Button>
          );
        })}
      </Box>
    ));
  };

  return (
    <Box className="ekg-quiz-container">
      <Typography variant="h4" gutterBottom>
        {quizName}
      </Typography>

      {/* Ergebnis anzeigen, wenn vorhanden und im Testmodus */}
      {isTestMode && quizResult && (
        <Alert 
          severity={quizResult.correctPercentage >= 80 ? "success" : 
                   quizResult.correctPercentage >= 50 ? "warning" : "error"}
          sx={{ mb: 2 }}
        >
          <AlertTitle>Quiz Ergebnis</AlertTitle>
          <Typography>Korrekt: {quizResult.correctCount} von {quizResult.totalCorrect} ({quizResult.correctPercentage}%)</Typography>
          <Typography>Falsch ausgewählt: {quizResult.incorrectCount}</Typography>
          <Typography>Nicht ausgewählt: {quizResult.missedCount}</Typography>
        </Alert>
      )}

      {loading && <Typography>Lade Daten...</Typography>}
      {error && <Typography color="error">{error}</Typography>}

      {/* EKG Image */}
      <Paper 
        className="ekg-image-container" 
        onClick={() => setImageOpen(true)}
      >
        <img 
          src={image} 
          alt="EKG"
          className="ekg-image"
        />
        <IconButton className="zoom-button">
          <ZoomInIcon />
        </IconButton>
      </Paper>

      {/* Collapsible Text Panel */}
      <Paper className={`ekg-description-panel ${!description ? 'disabled' : ''}`}>
        <Button 
          fullWidth
          onClick={toggleTextExpand}
          disabled={!description}
          className="expand-description-button"
          endIcon={<KeyboardArrowDownIcon className={textExpanded ? 'arrow-expanded' : ''} />}
        >
          <Typography variant="button" className="expand-text">
            {textExpanded ? "Beschreibung ausblenden" : "Beschreibung anzeigen"}
          </Typography>
        </Button>
        <Collapse in={textExpanded} timeout="auto">
          <Box className="ekg-description-content">
            <Typography>
              {description}
            </Typography>
          </Box>
        </Collapse>
      </Paper>

      {/* Selected Items Box */}
      <Paper className="selected-items-box">
        <Typography variant="h6" className="selected-items-title">
          Ausgewählte Elemente
        </Typography>
        <List>
          {selectedItems.length > 0 ? (
            selectedItems.map((item, index) => {
              // Display-Namen für das Element finden
              let displayName = item;
              
              if (isTestMode) {
                // Für Standard-Optionen
                if (item.startsWith('opt-')) {
                  const parts = item.split('-');
                  if (parts.length >= 3) {
                    displayName = parts.slice(2).join('-').replace(/-/g, ' ');
                  }
                } 
                // Für benutzerdefinierte Optionen
                else if (item.startsWith('custom-')) {
                  // Finde den Namen in den benutzerdefinierten Kategorien
                  if (quizData && quizData.checked_elemente?.custom?.kategorien) {
                    quizData.checked_elemente.custom.kategorien.forEach(category => {
                      // Suche in direkten Optionen
                      category.optionen.forEach(option => {
                        if (option.id === item) displayName = option.name;
                      });
                      
                      // Suche in Unterkategorien
                      category.unterkategorien.forEach(subcat => {
                        subcat.optionen.forEach(option => {
                          if (option.id === item) displayName = option.name;
                        });
                      });
                    });
                  }
                }
              }
              
              return (
                <ListItem 
                  key={index}
                  secondaryAction={
                    <IconButton edge="end" onClick={() => removeItem(index)} disabled={quizResult !== null}>
                      <DeleteIcon />
                    </IconButton>
                  }
                >
                  <ListItemText primary={displayName} />
                </ListItem>
              );
            })
          ) : (
            <ListItem>
              <ListItemText 
                primary="Keine Elemente ausgewählt" 
                secondary={isTestMode ? "Wähle Elemente über die Checkboxen aus" : "Wähle Elemente aus den Kategorien"} 
              />
            </ListItem>
          )}
        </List>
      </Paper>

      {/* Action Buttons */}
      <Box className="action-buttons">
        <Button 
          variant="contained" 
          color="error" 
          onClick={clearAll}
          disabled={quizResult !== null || selectedItems.length === 0}
        >
          Alle löschen
        </Button>
        <Button 
          variant="contained" 
          color="success" 
          onClick={handleSubmit}
          disabled={quizResult !== null || selectedItems.length === 0}
        >
          {isTestMode ? "Überprüfen" : "Senden"}
        </Button>
        {quizResult && !disableRetry && (
          <Button 
            variant="contained" 
            color="primary" 
            onClick={handleRetry}
          >
            Erneut versuchen
          </Button>
        )}
      </Box>

      {/* Accordion Groups */}
      {!loading && (
        <Grid container spacing={2}>
          {isTestMode ? (
            <>
              {renderStandardCategories()}
              {renderCustomCategories()}
            </>
          ) : (
            renderAllCategories()
          )}
        </Grid>
      )}

      {/* Image Zoom Dialog */}
      <Dialog 
        open={imageOpen} 
        onClose={() => setImageOpen(false)}
        maxWidth="xl"
        fullWidth
      >
        <IconButton 
          onClick={() => setImageOpen(false)}
          className="dialog-close-button"
        >
          <CloseIcon />
        </IconButton>
        <TransformWrapper
          initialScale={1}
          minScale={0.5}
          maxScale={8}
          wheel={{ step: 0.2 }}
          pinch={{ 
            enabled: true,
            step: 5,
            wheelEnabled: true,
            touchEnabled: true 
          }}
          doubleClick={{ enabled: true, mode: "reset" }}
          panning={{ enabled: true, touchPadEnabled: true }}
        >
          {({ zoomIn, zoomOut, resetTransform }) => (
            <>
              <Box className="zoom-controls">
                <IconButton onClick={() => zoomIn()}>
                  <ZoomInIcon />
                </IconButton>
                <IconButton onClick={() => zoomOut()}>
                  <ZoomOutIcon />
                </IconButton>
                <IconButton onClick={() => resetTransform()}>
                  <RestartAltIcon />
                </IconButton>
              </Box>
              <TransformComponent>
                <img 
                  src={image} 
                  alt="EKG"
                  className="ekg-image-full"
                />
              </TransformComponent>
            </>
          )}
        </TransformWrapper>
      </Dialog>

      {/* Feedback Dialog */}
      <Dialog
        open={feedbackDialogOpen}
        onClose={handleCloseFeedbackDialog}
        maxWidth="md"
        fullWidth
        className="feedback-dialog"
      >
        <DialogTitle>
          <Box display="flex" justifyContent="space-between" alignItems="center">
            <Typography variant="h6">Auswertung: {quizData?.name || "EKG Quiz"}</Typography>
            <IconButton onClick={handleCloseFeedbackDialog}>
              <CloseIcon />
            </IconButton>
          </Box>
        </DialogTitle>
        
        <DialogContent dividers>
          {quizResult && (
            <>
              {/* Ergebnis-Zusammenfassung */}
              <Box className="result-summary" mb={3} p={2} bgcolor="background.paper" borderRadius={1}>
                <Typography variant="h5" gutterBottom>Gesamtergebnis</Typography>
                
                <Box display="flex" alignItems="center" mb={2}>
                  <Box 
                    width={quizResult.correctPercentage + "%"} 
                    bgcolor={
                      quizResult.correctPercentage >= 80 ? "success.light" : 
                      quizResult.correctPercentage >= 50 ? "warning.light" : "error.light"
                    }
                    height="20px"
                    borderRadius={1}
                    position="relative"
                  >
                    <Typography 
                      variant="body2" 
                      color="white" 
                      position="absolute" 
                      left="50%" 
                      top="50%" 
                      style={{ transform: 'translate(-50%, -50%)' }}
                    >
                      {quizResult.correctPercentage}%
                    </Typography>
                  </Box>
                </Box>
                
                <Box display="flex" justifyContent="space-between" mb={1}>
                  <Box>
                    <Typography variant="body1">
                      <b>{quizResult.correctCount}</b> von <b>{quizResult.totalCorrect}</b> richtigen Elementen gefunden
                    </Typography>
                  </Box>
                  <Box>
                    <Typography variant="body1" color="error.main">
                      <b>{quizResult.incorrectCount}</b> falsche Auswahl(en)
                    </Typography>
                  </Box>
                </Box>
                
                <Alert 
                  severity={
                    quizResult.correctPercentage >= 80 ? "success" : 
                    quizResult.correctPercentage >= 50 ? "warning" : "error"
                  }
                  sx={{ mt: 2 }}
                >
                  <AlertTitle>
                    {quizResult.correctPercentage >= 80 ? "Sehr gut!" : 
                     quizResult.correctPercentage >= 50 ? "Teilweise richtig" : "Noch Verbesserungsbedarf"}
                  </AlertTitle>
                  {quizResult.correctPercentage >= 80 
                    ? "Du hast die meisten richtigen Elemente gefunden." 
                    : quizResult.correctPercentage >= 50 
                    ? "Du hast einige richtige Elemente gefunden, aber noch nicht alle."
                    : "Du hast nur wenige richtige Elemente gefunden."}
                </Alert>
              </Box>
              
              <Divider sx={{ my: 2 }} />
              
              {/* Detaillierte Ergebnisse */}
              <Typography variant="h6" gutterBottom>Detaillierte Auswertung</Typography>
              
              {/* Korrekte Auswahlen */}
              {quizResult && quizResult.correctSelections && quizResult.correctSelections.length > 0 && (
                <Box mb={3}>
                  <Typography variant="subtitle1" color="success.main" gutterBottom>
                    Korrekt ausgewählt ({quizResult.correctSelections.length})
                  </Typography>
                  <Paper variant="outlined" sx={{ p: 1 }}>
                    <List dense>
                      {(quizResult.correctSelections || []).map((item, index) => (
                        <ListItem key={index}>
                          <ListItemIcon>
                            <CheckCircleOutlineIcon color="success" />
                          </ListItemIcon>
                          <ListItemText primary={getDisplayNameForItem(item)} />
                        </ListItem>
                      ))}
                    </List>
                  </Paper>
                </Box>
              )}
              
              {/* Falsche Auswahlen */}
              {quizResult && quizResult.incorrectSelections && quizResult.incorrectSelections.length > 0 && (
                <Box mb={3}>
                  <Typography variant="subtitle1" color="error.main" gutterBottom>
                    Falsch ausgewählt ({quizResult.incorrectSelections.length})
                  </Typography>
                  <Paper variant="outlined" sx={{ p: 1 }}>
                    <List dense>
                      {(quizResult.incorrectSelections || []).map((item, index) => (
                        <ListItem key={index}>
                          <ListItemIcon>
                            <CancelIcon color="error" />
                          </ListItemIcon>
                          <ListItemText primary={getDisplayNameForItem(item)} />
                        </ListItem>
                      ))}
                    </List>
                  </Paper>
                </Box>
              )}
              
              {/* Fehlende Elemente */}
              {quizResult && quizResult.missedAnswers && quizResult.missedAnswers.length > 0 && (
                <Box mb={3}>
                  <Typography variant="subtitle1" color="warning.main" gutterBottom>
                    Nicht ausgewählt ({quizResult.missedAnswers.length})
                  </Typography>
                  <Paper variant="outlined" sx={{ p: 1 }}>
                    <List dense>
                      {(quizResult.missedAnswers || []).map((item, index) => (
                        <ListItem key={index}>
                          <ListItemIcon>
                            <ErrorOutlineIcon color="warning" />
                          </ListItemIcon>
                          <ListItemText primary={getDisplayNameForItem(item)} />
                        </ListItem>
                      ))}
                    </List>
                  </Paper>
                </Box>
              )}
            </>
          )}
        </DialogContent>
        
        <DialogActions>
          <Button onClick={handleCloseFeedbackDialog} color="primary">
            Schließen
          </Button>
          {!disableRetry && (
            <Button onClick={handleRetry} color="primary" variant="contained">
              Erneut versuchen
            </Button>
          )}
        </DialogActions>
      </Dialog>
    </Box>
  );
}

export default EkgQuiz;