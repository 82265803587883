import React, { useState, useContext, useEffect } from 'react';
import { Container, Typography, TextField, Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, IconButton, Menu, MenuItem } from '@mui/material';
import { Editor } from '@tinymce/tinymce-react';
import axios from 'axios';
import { useParams, useNavigate } from 'react-router-dom';
import AuthContext from '../AuthContext';
import EditIcon from '@mui/icons-material/Edit';
import AddIcon from '@mui/icons-material/Add';
import TextFieldsIcon from '@mui/icons-material/TextFields';
import BarChartIcon from '@mui/icons-material/BarChart';
import ArrowUpwardIcon from '@mui/icons-material/ArrowUpward';
import ArrowDownwardIcon from '@mui/icons-material/ArrowDownward';
import DeleteIcon from '@mui/icons-material/Delete';
import EKG_Quiz from './EKG_Quiz';
import EKG_QuizManager from './EKG_QuizManager';

// Füge diese Hilfsfunktion in KapitelFormular.js ein, vor der Hauptkomponente
const normalizeQuizData = (quizContent) => {
  if (!quizContent) return { bild_url: '', konfiguration: { name: 'Neues EKG Quiz', fragen: [], fragen_ids: [] } };
  
  // Stelle sicher, dass konfiguration ein Objekt ist und nicht ein String
  let konfiguration = quizContent.konfiguration || {};
  
  // Wenn konfiguration ein String ist, parsen
  if (typeof konfiguration === 'string') {
    try {
      konfiguration = JSON.parse(konfiguration);
      console.log("Konfiguration erfolgreich geparst aus String");
    } catch (error) {
      console.error("Fehler beim Parsen der Konfiguration:", error);
      konfiguration = {};
    }
  }
  
  // Stelle sicher, dass fragen ein Array ist
  if (!Array.isArray(konfiguration.fragen)) {
    konfiguration.fragen = [];
  }
  
  // Stelle sicher, dass fragen_ids ein Array ist
  if (!Array.isArray(konfiguration.fragen_ids)) {
    // Wenn keine fragen_ids vorhanden sind, aber fragen, extrahiere die IDs
    if (konfiguration.fragen.length > 0) {
      konfiguration.fragen_ids = konfiguration.fragen.map(frage => frage.id).filter(Boolean);
    } else {
      konfiguration.fragen_ids = [];
    }
  }
  
  // Wenn fragen_ids vorhanden sind, aber keine fragen, stellen wir sicher, dass
  // dies konsistent ist für EKG_QuizManager
  if (konfiguration.fragen_ids.length > 0 && konfiguration.fragen.length === 0) {
    console.log("QuizData hat fragen_ids, aber keine fragen. Initialisiere leere Fragen-Objekte.");
    konfiguration.fragen = konfiguration.fragen_ids.map(id => ({
      id,
      name: `Frage ${id}`,
      bild_url: '',
      klappentext: ''
    }));
  }
  
  // Stelle sicher, dass name existiert
  konfiguration.name = konfiguration.name || 'EKG Quiz';
  
  return {
    bild_url: quizContent.bild_url || '',
    konfiguration
  };
};

function KapitelFormular() {
  const { kapitelId } = useParams();
  const [kapitelName, setKapitelName] = useState('');
  const [inhalte, setInhalte] = useState([]);
  const [loading, setLoading] = useState(true);
  const { token } = useContext(AuthContext);
  const navigate = useNavigate();
  const [editNameDialog, setEditNameDialog] = useState(false);
  const [newKapitelName, setNewKapitelName] = useState('');
  const [addMenuOpen, setAddMenuOpen] = useState(false);
  const addButtonRef = React.useRef(null);
  const [message, setMessage] = useState('');
  const [openDialog, setOpenDialog] = useState(false);
  const [anchorEl, setAnchorEl] = useState(null);
  const [confirmDeleteOpen, setConfirmDeleteOpen] = useState(false);
  const [confirmDeleteItem, setConfirmDeleteItem] = useState(null);

  useEffect(() => {
    if (kapitelId) {
      axios
        .get(`/api/kapitelMitInhalten/${kapitelId}`, {
          headers: { Authorization: `Bearer ${token}` },
        })
        .then((response) => {
          setKapitelName(response.data.name);
          
          // Normalisiere die Inhalte, insbesondere für EKG-Quiz
          const normalizedInhalte = (response.data.inhalte || []).map(inhalt => {
            // Stelle sicher, dass kapitel_inhalt_id richtig gesetzt ist
            if (!inhalt.kapitel_inhalt_id && inhalt.id) {
              console.log(`Setze kapitel_inhalt_id = id für Inhalt ${inhalt.id}`);
              inhalt.kapitel_inhalt_id = inhalt.id;
            }
            
            if (inhalt.type === 'ekgquiz') {
              return {
                ...inhalt,
                content: normalizeQuizData(inhalt.content)
              };
            }
            return inhalt;
          });
          
          setInhalte(normalizedInhalte);
          setLoading(false);
          
          // Debug-Log für Quiz-Inhalte
          const quizInhalte = normalizedInhalte.filter(i => i.type === 'ekgquiz');
          if (quizInhalte.length > 0) {
            console.log("Geladene Quiz-Inhalte:", quizInhalte);
          }
        })
        .catch(error => {
          console.error('Fehler beim Laden:', error);
          setLoading(false);
        });
    }
  }, [kapitelId, token]);

  const addInhalt = async (type) => {
    const newPosition = inhalte.length > 0 
      ? Math.max(...inhalte.map(i => i.position)) + 1 
      : 0;
    
    let newInhalt = {
      type,
      id: `temp-${Date.now()}`,
      position: newPosition,
      isNew: true
    };
    
    try {
      if (type === 'text') {
        newInhalt.content = '';
        
        // Sofort in der Datenbank speichern
        const textResponse = await axios.post(`/api/kapitel/${kapitelId}/textInhalt`, {
          inhalt: '',
          position: newPosition
        }, {
          headers: { Authorization: `Bearer ${token}` }
        });
        
        // IDs aktualisieren
        newInhalt.id = textResponse.data.inhalt_id;
        newInhalt.kapitel_inhalt_id = textResponse.data.kapitel_inhalt_id;
        newInhalt.isNew = false;
        
      } else if (type === 'ekgquiz') {
        // Erstelle direkt ein Quiz in der Datenbank
        const quizResponse = await axios.post('/api/ekgQuiz', {
          name: 'Neues EKG Quiz',
          fragen_ids: [],
          berechtigte_dozenten: [-1]
        }, {
          headers: { Authorization: `Bearer ${token}` }
        });
        
        const quiz_id = quizResponse.data.quiz_id;
        console.log("Neues Quiz erstellt mit ID:", quiz_id);
        
        // Vorbereiten des Inhalts
        newInhalt.content = {
          bild_url: '',
          konfiguration: {
            quiz_id: quiz_id,
            name: 'Neues EKG Quiz',
            fragen: [],
            fragen_ids: []
          }
        };
        
        // Sofort in der Datenbank speichern
        const ekgResponse = await axios.post(`/api/kapitel/${kapitelId}/ekgQuizInhalt`, {
          quiz_id: quiz_id,
          position: newPosition,
          bild_url: '',
          konfiguration: newInhalt.content.konfiguration
        }, {
          headers: { Authorization: `Bearer ${token}` }
        });
        
        // IDs aktualisieren
        newInhalt.id = ekgResponse.data.inhalt_id;
        newInhalt.kapitel_inhalt_id = ekgResponse.data.kapitel_inhalt_id;
        newInhalt.isNew = false;

        // Debug-Log für die IDs
        console.log("Neue ekgQuiz-Inhalte IDs:", {
          id: newInhalt.id,
          kapitel_inhalt_id: newInhalt.kapitel_inhalt_id,
          quiz_id: ekgResponse.data.quiz_id
        });
      }
      
      // Inhalt zum lokalen State hinzufügen
      setInhalte([...inhalte, newInhalt]);
      
      // Erfolgsmeldung anzeigen
      setMessage(`${type === 'text' ? 'Text' : 'Quiz'} erfolgreich hinzugefügt`);
      setOpenDialog(true);
      
    } catch (error) {
      console.error('Fehler beim Erstellen des Inhalts:', error);
      setMessage('Fehler beim Erstellen des Inhalts: ' + (error.response?.data?.message || error.message));
      setOpenDialog(true);
    }
  };

  const updateInhalt = async (id, field, value) => {
    // Zuerst den aktuellen Inhalt finden
    const currentInhalt = inhalte.find(item => item.id === id);
    
    // Wenn der Inhalt nicht gefunden wurde oder sich der Wert nicht geändert hat, nichts tun
    if (!currentInhalt || JSON.stringify(currentInhalt[field]) === JSON.stringify(value)) {
      return;
    }
    
    // Ansonsten den Zustand aktualisieren
    const updatedInhalte = inhalte.map(item => 
      item.id === id ? { ...item, [field]: value } : item
    );
    setInhalte(updatedInhalte);
    
    // Sofort in der Datenbank speichern
    try {
      const updatedItem = updatedInhalte.find(item => item.id === id);
      
      if (updatedItem.type === 'text') {
        await axios.put(`/api/textInhalt/${id}`, {
          inhalt: updatedItem.content,
          position: updatedItem.position
        }, {
          headers: { Authorization: `Bearer ${token}` }
        });
      } else if (updatedItem.type === 'ekgquiz') {
        await axios.put(`/api/ekgQuizInhalt/${id}`, {
          bild_url: updatedItem.content.bild_url || '',
          konfiguration: updatedItem.content.konfiguration || {},
          position: updatedItem.position
        }, {
          headers: { Authorization: `Bearer ${token}` }
        });
      }
    } catch (error) {
      console.error('Fehler beim Aktualisieren des Inhalts:', error);
      // Keine Fehlermeldung anzeigen, damit die Benutzeroberfläche nicht zu oft unterbrochen wird
    }
  };

  const deleteInhalt = async (id) => {
    // Finde den Inhalt
    const inhaltToDelete = inhalte.find(item => item.id === id);
    if (!inhaltToDelete) {
      console.error(`Inhalt mit ID ${id} nicht gefunden`);
      return;
    }
    
    // Debug-Log für den zu löschenden Inhalt
    console.log("Zu löschender Inhalt:", {
      id: inhaltToDelete.id,
      kapitel_inhalt_id: inhaltToDelete.kapitel_inhalt_id,
      type: inhaltToDelete.type
    });
    
    // Bestätigungsdialog anzeigen
    setConfirmDeleteItem(inhaltToDelete);
    setConfirmDeleteOpen(true);
  };

  const confirmDelete = async () => {
    const inhaltToDelete = confirmDeleteItem;
    setConfirmDeleteOpen(false);
    
    if (!inhaltToDelete) {
      setMessage('Fehler: Kein Inhalt zum Löschen ausgewählt');
      setOpenDialog(true);
      return;
    }
    
    try {
      // Lösche den Inhalt aus der Datenbank
      if (!inhaltToDelete.isNew) {
        // Verwende zuerst kapitel_inhalt_id, falls vorhanden
        const deleteId = inhaltToDelete.kapitel_inhalt_id || inhaltToDelete.id;
        
        console.log(`Lösche Kapitelinhalt mit ID: ${deleteId}`);
        
        if (!deleteId) {
          throw new Error("Kapitelinhalt-ID fehlt");
        }
        
        await axios.delete(`/api/kapitelInhalt/${deleteId}`, {
          headers: { Authorization: `Bearer ${token}` }
        });
        
        // Falls es ein Quiz ist, versuche auch das Quiz selbst zu löschen
        if (inhaltToDelete.type === 'ekgquiz' && inhaltToDelete.content?.konfiguration?.quiz_id) {
          try {
            const quizId = inhaltToDelete.content.konfiguration.quiz_id;
            console.log(`Lösche auch das Quiz mit ID: ${quizId}`);
            await axios.delete(`/api/ekgQuiz/${quizId}`, {
              headers: { Authorization: `Bearer ${token}` }
            });
          } catch (quizError) {
            console.warn("Fehler beim Löschen des Quiz:", quizError);
            // Ignoriere Fehler beim Löschen des Quiz, da der Kapitelinhalt bereits gelöscht wurde
          }
        }
      }
      
      // Aus lokalem State entfernen
      setInhalte(inhalte.filter(item => item.id !== inhaltToDelete.id));
      
      // Erfolgsmeldung anzeigen
      setMessage('Inhalt erfolgreich gelöscht');
      setOpenDialog(true);
    } catch (error) {
      console.error('Fehler beim Löschen:', error);
      setMessage('Fehler beim Löschen des Inhalts: ' + (error.response?.data?.message || error.message));
      setOpenDialog(true);
    }
  };

  const renderEditor = (inhalt) => {
    switch(inhalt.type) {
      case 'text':
        return (
          <Editor
            tinymceScriptSrc='/tinymce/tinymce.min.js'
            value={inhalt.content}
            init={{
              license_key: 'gpl',
              height: 300,
              menubar: false,
              plugins: [
                'link', 'lists', 'image', 'media', 'advlist',
                'table', 'code'
              ],
              toolbar: 
                'undo redo | formatselect | ' +
                'fontfamily fontsize | ' +
                'bold italic | forecolor backcolor | ' +
                'alignleft aligncenter alignright alignjustify | ' +
                'bullist numlist outdent indent | ' +
                'removeformat | help | image media',
              font_formats: [
                'Arial=arial,helvetica,sans-serif',
                'Arial Black=arial black,avant garde',
                'Book Antiqua=book antiqua,palatino',
                'Comic Sans MS=comic sans ms,sans-serif',
                'Courier New=courier new,courier',
                'Georgia=georgia,palatino',
                'Helvetica=helvetica',
                'Impact=impact,chicago',
                'Symbol=symbol',
                'Tahoma=tahoma,arial,helvetica,sans-serif',
                'Times New Roman=times new roman,times',
                'Verdana=verdana,geneva'
              ].join(';'),
              fontsize_formats: '8pt 10pt 12pt 14pt 16pt 18pt 24pt 36pt',
              images_upload_url: '/api/upload-image',
              automatic_uploads: true,
              
              // Bild-Upload-Handler
              images_upload_handler: (blobInfo, success, failureCallback) => {
                const formData = new FormData();
                formData.append('file', blobInfo.blob());
                axios.post('/api/upload-image', formData, {
                  headers: {
                    Authorization: `Bearer ${token}`,
                    'Content-Type': 'multipart/form-data',
                  },
                })
                .then(response => {
                  success(response.data.location);
                })
                .catch(() => {
                  failureCallback('Bild konnte nicht hochgeladen werden.');
                });
              },
              
              // Video- und Audio-Upload-Handler
              file_picker_types: 'image media',
              file_picker_callback: function (callback, value, meta) {
                const input = document.createElement('input');
                input.setAttribute('type', 'file');
                if (meta.filetype === 'image') {
                  input.setAttribute('accept', 'image/*');
                } else {
                  input.setAttribute('accept', 'audio/*,video/*');
                }
                input.onchange = function () {
                  const file = this.files[0];
                  const formData = new FormData();
                  formData.append('file', file);
                  
                  axios.post(
                    file.type.startsWith('audio') ? '/api/upload-audio' : '/api/upload-video',
                    formData,
                    {
                      headers: {
                        Authorization: `Bearer ${token}`,
                        'Content-Type': 'multipart/form-data',
                      },
                    }
                  )
                  .then(response => {
                    callback(response.data.location);
                  })
                  .catch(() => {
                    alert('Medien konnten nicht hochgeladen werden.');
                  });
                };
                input.click();
              },
              
              media_live_embeds: true,
              media_dimensions: false,
              media_alt_source: false,
              media_poster: true,
              content_style: `
                img, video { 
                  max-width: 100% !important; 
                  height: auto !important;
                  display: block !important;
                  margin: 1em auto !important;
                }
                iframe {
                  width: 100% !important;
                  aspect-ratio: 16/9;
                  margin: 1em auto !important;
                }
              `,
              setup: (editor) => {
                // Wenn Objekte (Bilder) resized werden
                editor.on('ObjectResized', (e) => {
                  const { target } = e;
                  if (target.nodeName.toLowerCase() === 'img') {
                    // Behalte die Breite als Prozentsatz statt px
                    const width = parseInt(target.style.width, 10);
                    if (!isNaN(width)) {
                      // Setze explizit Attribute, nicht nur style
                      target.setAttribute('width', width + '%');
                      target.removeAttribute('height'); // Höhe entfernen für korrektes Seitenverhältnis
                    }
                    
                    // Zusätzlich die style-Eigenschaften setzen
                    target.style.width = width + '%';
                    target.style.height = 'auto';
                  }
                });
                
                // Vor dem Setzen des Inhalts
                editor.on('BeforeSetContent', (e) => {
                  // Breite/Höhe-Attribute NICHT entfernen, da wir sie brauchen
                  // Stattdessen nur die px-Werte in %-Werte konvertieren
                  // Ersetze nur height-Attribute, behalte width für Skalierung
                  e.content = e.content.replace(/height="[^"]*"/g, '');
                  
                  // Konvertiere px in % für Bildbreiten
                  e.content = e.content.replace(/width="(\d+)px"/g, (match, width) => {
                    // Konvertiere px zu % basierend auf Containerbreite (angenommen ca. 800px)
                    const percent = Math.min(100, Math.round((parseInt(width, 10) / 800) * 100));
                    return `width="${percent}%"`;
                  });
                  
                  // Für iframes: Video-Container für Responsivität
                  if (e.content.includes('<iframe')) {
                    e.content = e.content.replace(
                      /<iframe([^>]*)>/g,
                      '<div class="video-container"><iframe$1>'
                    ).replace(
                      /<\/iframe>/g,
                      '</iframe></div>'
                    );
                  }
                });
                
                // Nach dem Laden des Inhalts
                editor.on('SetContent', () => {
                  // Finde alle Bilder im Editor
                  const images = editor.dom.select('img');
                  images.forEach(img => {
                    // Stelle sicher, dass style.width in % statt px ist, falls vorhanden
                    if (img.style.width && img.style.width.endsWith('px')) {
                      const width = parseInt(img.style.width, 10);
                      const containerWidth = editor.getBody().clientWidth;
                      const percent = Math.min(100, Math.round((width / containerWidth) * 100));
                      img.style.width = percent + '%';
                      img.style.height = 'auto';
                    }
                  });
                });
              }
            }}
            onEditorChange={(content) => updateInhalt(inhalt.id, 'content', content)}
          />
        );
      case 'ekgquiz':
        return (
          <EKG_QuizManager 
            config={inhalt.content.konfiguration}
            bildUrl={inhalt.content.bild_url}
            editorMode={true}
            onChange={(updatedConfig) => {
              // Zuerst prüfen, ob sich die Konfiguration tatsächlich geändert hat
              if (JSON.stringify(inhalt.content.konfiguration) !== JSON.stringify(updatedConfig)) {
                updateInhalt(inhalt.id, 'content', {
                  ...inhalt.content,
                  konfiguration: updatedConfig
                });
              }
            }}
            onImageChange={(url) => {
              // Nur aktualisieren, wenn sich die URL tatsächlich geändert hat
              if (inhalt.content.bild_url !== url) {
                updateInhalt(inhalt.id, 'content', {
                  ...inhalt.content,
                  bild_url: url
                });
              }
            }}
          />
        );
    }
  };

  const handleUpdateName = async () => {
    try {
      const response = await axios.put(`/api/updateKapitelName/${kapitelId}`, {
        name: newKapitelName
      }, {
        headers: {
          Authorization: `Bearer ${token}`
        }
      });
      setKapitelName(newKapitelName);
      setEditNameDialog(false);
      setMessage('Kapitelname erfolgreich aktualisiert');
      setOpenDialog(true);
    } catch (error) {
      console.error('Fehler beim Aktualisieren des Kapitelnamens:', error);
      setMessage('Fehler beim Aktualisieren des Kapitelnamens');
      setOpenDialog(true);
    }
  };

  const moveInhalt = (id, direction) => {
    const currentIndex = inhalte.findIndex(item => item.id === id);
    if (currentIndex === -1) return;
    
    // Nicht bewegen, wenn bereits am Anfang/Ende
    if (direction === 'up' && currentIndex === 0) return;
    if (direction === 'down' && currentIndex === inhalte.length - 1) return;
    
    const newIndex = direction === 'up' ? currentIndex - 1 : currentIndex + 1;
    const newInhalte = [...inhalte];
    
    // Positionen tauschen
    [newInhalte[currentIndex].position, newInhalte[newIndex].position] = 
    [newInhalte[newIndex].position, newInhalte[currentIndex].position];
    
    // Nach Position sortieren
    newInhalte.sort((a, b) => a.position - b.position);
    
    setInhalte(newInhalte);
  };

  if (loading) {
    return <div>Loading...</div>;
  }

  return (
    <Container maxWidth="md">
      <div style={{ display: 'flex', alignItems: 'center', marginTop: '20px' }}>
        <Typography variant="h4" gutterBottom style={{ fontWeight: 'bold', marginRight: '10px' }}>
          {kapitelName} bearbeiten
        </Typography>
        <IconButton onClick={() => {
          setNewKapitelName(kapitelName);
          setEditNameDialog(true);
        }}>
          <EditIcon />
        </IconButton>
      </div>

      <Dialog open={editNameDialog} onClose={() => setEditNameDialog(false)}>
        <DialogTitle>Kapitelnamen bearbeiten</DialogTitle>
        <DialogContent>
          <TextField
            autoFocus
            margin="dense"
            label="Kapitelname"
            fullWidth
            value={newKapitelName}
            onChange={(e) => setNewKapitelName(e.target.value)}
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setEditNameDialog(false)}>Abbrechen</Button>
          <Button onClick={handleUpdateName}>Speichern</Button>
        </DialogActions>
      </Dialog>

      <div className="inhalte-container">
        {inhalte && inhalte.length > 0 ? (
          inhalte.map((inhalt) => (
            <div key={inhalt.id} className="inhalt-block">
              <div className="inhalt-controls">
                <IconButton onClick={() => moveInhalt(inhalt.id, 'up')}><ArrowUpwardIcon /></IconButton>
                <IconButton onClick={() => moveInhalt(inhalt.id, 'down')}><ArrowDownwardIcon /></IconButton>
                <IconButton onClick={() => deleteInhalt(inhalt.id)}><DeleteIcon /></IconButton>
              </div>
              {renderEditor(inhalt)}
            </div>
          ))
        ) : (
          <Typography variant="body1" sx={{ my: 3, textAlign: 'center' }}>
            Keine Inhalte vorhanden. Fügen Sie Inhalte mit der Schaltfläche unten hinzu.
          </Typography>
        )}
      </div>
      
      <div className="add-inhalt">
        <Button 
          variant="contained" 
          startIcon={<AddIcon />}
          onClick={(event) => {
            // Setze das Anchor-Element auf das aktuelle Event-Target (der Button)
            setAnchorEl(event.currentTarget);
            setAddMenuOpen(true);
          }}
          ref={addButtonRef}
        >
          Inhalt hinzufügen
        </Button>
        <Menu
          open={addMenuOpen}
          onClose={() => {
            setAddMenuOpen(false);
            setAnchorEl(null);
          }}
          anchorEl={anchorEl}
          anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'center',
          }}
          transformOrigin={{
            vertical: 'top',
            horizontal: 'center',
          }}
        >
          <MenuItem onClick={() => { addInhalt('text'); setAddMenuOpen(false); setAnchorEl(null); }}>
            <TextFieldsIcon sx={{ mr: 1 }} /> Text & Medien
          </MenuItem>
          <MenuItem onClick={() => { addInhalt('ekgquiz'); setAddMenuOpen(false); setAnchorEl(null); }}>
            <BarChartIcon sx={{ mr: 1 }} /> EKG Quiz
          </MenuItem>
        </Menu>
      </div>

      <div style={{ marginTop: '30px', display: 'flex', justifyContent: 'flex-end' }}>
        <Button onClick={() => navigate(`/kapitel/${kapitelId}`)} variant="contained" color="secondary">
          Kapitel ansehen
        </Button>
      </div>

      <Dialog open={openDialog} onClose={() => setOpenDialog(false)}>
        <DialogTitle>Info</DialogTitle>
        <DialogContent>
          <DialogContentText>{message}</DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setOpenDialog(false)}>Schließen</Button>
        </DialogActions>
      </Dialog>

      <Dialog
        open={confirmDeleteOpen}
        onClose={() => setConfirmDeleteOpen(false)}
      >
        <DialogTitle>Inhalt löschen?</DialogTitle>
        <DialogContent>
          <DialogContentText>
            Möchten Sie diesen Inhalt wirklich löschen? 
            {confirmDeleteItem?.type === 'ekgquiz' && " Das Quiz und alle zugehörigen Fragen werden permanent gelöscht!"}
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setConfirmDeleteOpen(false)}>Abbrechen</Button>
          <Button onClick={confirmDelete} color="error">Löschen</Button>
        </DialogActions>
      </Dialog>
    </Container>
  );
}

export default KapitelFormular;
