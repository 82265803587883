import React, { useEffect, useContext } from 'react';
import { ThemeProvider } from '@mui/material/styles';
import theme from './theme';
import './colors.css';
import { BrowserRouter as Router, Route, Routes, Navigate } from 'react-router-dom';
import MainPage from './MainPage';
import LandingPage from './LandingPage';
import Header from './Header';
import UserProfile from './UserProfile';
import EinführungEchokardiographie from './EinführungEchokardiographie';
import Termine from './Termine';
import Login from './Login';
import TestComponent from './TestComponent';
import Register from './Register';
import Kurse from './Kurse';
import FAQ from './FAQ';
import Kontakt from './Kontakt';
import AuthContext from './AuthContext';
import KursTitelseiteFormular from './Kurskomponenten/KursTitelseiteFormular';
import KursTitelseite from './Kurskomponenten/KursTitelseite'; // Importiere die KursTitelseite
import Kursuebersicht from './Kurskomponenten/Kursuebersicht';
import KapitelFormular from './Kurskomponenten/KapitelFormular';
import KapitelPage from './Kurskomponenten/KapitelPage';
import EKG_QuizEditor from './Kurskomponenten/EKG_QuizEditor';
import './mui-overrides.css';

function App() {
  const { user, loading, setLoading, checkUserStatus } = useContext(AuthContext);

  useEffect(() => {
    const storedUser = localStorage.getItem('user');
    if (storedUser) {
      checkUserStatus(); // Überprüfe den Benutzerstatus nur, wenn ein Benutzer im lokalen Speicher vorhanden ist
    } else {
      setLoading(false); // Ladeindikator stoppen, wenn kein Benutzer im lokalen Speicher vorhanden ist
    }
  }, [checkUserStatus, setLoading]);

  if (loading) {
    return <div>Loading...</div>; // Ladeindikator während der Authentifizierung
  }

  return (
    <ThemeProvider theme={theme}>
      <Router>
        <div className="App">
          <Header />
          <Routes>
            <Route path="/" element={user ? <MainPage /> : <LandingPage />} />
            <Route path="/kurse" element={<Kurse />} />
            <Route path="/faq" element={<FAQ />} />
            <Route path="/kontakt" element={<Kontakt />} />
            <Route path="/profile" element={user ? <UserProfile /> : <Navigate to="/login" />} />
            <Route path="/termine" element={user ? <Termine /> : <Navigate to="/login" />} />
            <Route path="/test" element={user ? <TestComponent /> : <Navigate to="/login" />} />
            <Route path="/login" element={<Login />} />
            <Route path="/register" element={<Register />} />

            {/* Dynamische Route für KursTitelseite */}
            <Route path="/kursuebersicht/:kursId" element={<Kursuebersicht />} />
            <Route path="/kurs/:kursId" element={<KursTitelseiteFormular />} />
            <Route path="/titelseite/:kursId" element={<KursTitelseite />} />
            <Route path="/Titelseite/:kursname" element={<KursTitelseite />} />
            <Route path="/kapitel/:kapitelId" element={<KapitelPage />} />
            <Route path="/kapitelformular/:kapitelId" element={<KapitelFormular />} />
            <Route 
              path="/ekg-quiz-editor/:frageId" 
              element={user ? <EKG_QuizEditor /> : <Navigate to="/login" />} 
            />

            <Route path="/*" element={<Navigate to="/" />} />
          </Routes>
        </div>
      </Router>
    </ThemeProvider>
  );
}

export default App;
